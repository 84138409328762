import React from 'react';

import { Styled } from './styled';
import Us from '../../assets/images/nosotros.svg';
import BackArrow from '../../assets/icons/ic_atras.svg';

const AboutUs = () => (
	<Styled id="about-us">
		<div className="page-title">
			<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
			<h2>¿Quienes somos?</h2>
			<span className="hide-on-med-and-down" />
		</div>

		<div className="wrapper">
			<img src={Us} alt="nosotros" />
			<div>
				<p>Somos un equipo, que trabaja para ofrecerte multiservicios de empresas y soluciones inmediatas en las áreas de auxilio vial, fontanería, electricidad, aire acondicionado, house keeping y más a través de las plataformas de QuickFix.</p>

				<p>Te ayudamos proveyendo atención según nuestras diferentes áreas de trabajo, aumentando tu bienestar y calidad de vida, por eso ponemos a tu disposición los servicios de nuestros aliados y equipos con profesionales altamente capacitados y listos para atenderte, además contamos con amplias zonas de cobertura, todo esto a tu alcance desde tu computadora o teléfono móvil.</p>

				<p>Garantizamos cada uno de nuestros servicios gracias al respaldo de nuestras alianzas y empresas asociadas.</p>
			</div>
		</div>
	</Styled>
);

export default AboutUs;