import styled from 'styled-components';

export const Wrapper = styled.header`
  height: 55px;

  @media (max-width: 992px) {
    height: 28px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    height: 100px;
    width: 100%;
    box-shadow:
      0 3px 7.4px rgba(0, 0, 0, 0.022),
      0 6.4px 25px rgba(0, 0, 0, 0.038),
      0 21px 112px rgba(0, 0, 0, 0.07)
		;
		padding: 0 55px;
    margin-left: auto;
    margin-right: auto;
    z-index: 200;
    
    @media (max-width: 992px) {
      height: 60px;
      padding: 0 20px;
    }

		.logo {
      img {
        height: 100px;
      }

      @media (max-width: 992px) {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;

        img {
          height: 50px;
        }
      }
    }
    
    .menu-button {
      background: none;
      height: 60px;
      width: 60px;
      border: none;
      cursor: pointer;
  
      img {
        height: 35px;
      }
    }

    #desktop-menu {
      display: flex;

      .menu-option {
        a {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }

    .icons-desktop {
      display: flex;
      justify-content: space-evenly;
      width: 200px;

      a {
        svg {
          transition: 0.3s;
        }
      }
    }
  }

  #mobile-menu {
    position: fixed;
    top: 45px;
    width: 100%;
    margin-bottom: 0;
    z-index: 10;
  }
`;

export const Menu = styled.ol`
  li {
    display: inherit;
    list-style: none;

    .social-icons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 200px;

      &::before, &::after {
        content: '|';
        font-size: 24px;
      }

      a {
        svg {
          height: 35px;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 60px;
    }

    .icons-mobile {
      display: flex;
      width: 100%;

      &::before, &::after {
        content: '';
      }
    }
  }

  .checkout-cart {
    position: relative;

    span {
      position: absolute;
      top: 3px;
      right: -10px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }
`;