import React, { useState, createContext } from 'react';

interface CartFields {
  length: number;
  slice: Function;
	map: Function;
	find: Function;
	reduce: Function;
  id: string;
  sku: string;
	title: string;
  price: number;
  extraKm: number;
  [key: number]: {title: string, price: number};
}

interface Props {
  cartState: CartFields[];
  setCartState: Function;
};

export const CartContext = createContext<Props>({
  cartState: [],
  setCartState: () => {}
});

export const CartProvider: React.FC = ({ children }) => {
  const initialState: any = window.localStorage.getItem("cart");
  const [cartState, setCartState] = useState(JSON.parse(initialState) || [])

  window.localStorage.setItem("cart", JSON.stringify(cartState))

  return (
    <CartContext.Provider value={{ cartState, setCartState }}>
      { children }
    </CartContext.Provider>
  )
}
