import styled from 'styled-components';

export const Styled = styled.div`
  position: relative;
  padding: 40px 0;
	border-radius: 5px;

	label {
		color: #0f0f0f;
		font-size: 14px;
	}

	.main-label {
		font-size: 21px;
		font-weight: 700;
		margin-bottom: 28px;
		margin-left: 0 !important;
	}

  p {
    padding: 0;
    margin: 0 0 20px 0;
  }

  .contact-method {
    display: flex;
    justify-content: space-between;
    padding: 0 16px 0 16px;
  }

  .row {
    .address-option {
      @media (max-width: 576px) {
        margin-top: 12px;
      }
    }
	}

	.input-field {
		input {
			padding: 8px;
		}
		label {
			font-size: 17px;
			margin-left: 16px;
		}
	}

	.dropdown-trigger {
		height: 65px !important;
		padding-left: 20px !important;
	}

	#car-service-maps {
		p {
			font-size: 18px;
			text-align: start;
			margin-top: 40px;
		}

		button {
			display: block;
			padding: 16px 34px;
			border-radius: 10px;
			border-width: 2px;
			border-style: solid;
			margin: 40px auto;
		}
	}

  .form-warning {
		font-size: 14px;
		color: #e57373;
	}

	.custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		width: 380px;
		border-radius: 10px;
		border-width: 2px;
		margin: 80px auto 40px auto;
		transition: .3s;

		&:hover {
			transition: .3s;
		}

		@media (max-width: 576px) {
			width: 80%;
		}
	}

	.disabled-button {
		pointer-events: none;
		border-radius: 10px;
		border-width: 2px;
		border-style: solid;
	}

	.enabled-button {
		cursor: pointer;
		pointer-events: all;
	}

	.address-dropdown {
		select {
			display: block;
			margin-top: 40px;
		}

		.dropdown-trigger {
			display: none;
		}
	}

	.pac-target-input::placeholder {
		color: #232323;
	}

	.pac-container {
		&: first-child {
			margin-top: -10px !important;
			z-index: 10;
		}
	} 
`;