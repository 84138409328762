import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Mail from '../../../assets/icons/ic_mail.svg';
import AxiosInstance from '../../../API/axiosInstance';
import { Styled } from '../styled';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState(false);
	let history = useHistory();

	const resetPass = () => {
		AxiosInstance.post('users/password', {
			email
		})
			.then(() => history.push('/verify-password'))
			.catch((error) => {
				console.error(error);
				setError(true);
				setTimeout(() => setError(false), 5000);
			})
	}

	return (
		<Styled className="access-wrapper new-pass">
			{ error && <span className="error-msg">¡Lo sentimos! Hubo un error procesando tu petición</span>}
			<h1>Recupera tu contraseña</h1>
			<p>Ingresa tu correo electrónico y espera tu nueva contraseña temporal</p>
			<div className="input-with-icon">
				<img src={Mail} alt="Sobre de correo" />
				<input
					id="recover-password-email"
					required
					type="email"
					placeholder="Correo electrónico"
					onChange={({ target }) => {
						setEmail(target.value)
					}}
				/>
			</div>
			<button onClick={resetPass} className="custom-button">Enviar código</button>
			<a href="/verify-password" className="center">Ya tengo un código</a>
		</Styled>
	)
};

export default ForgotPassword;