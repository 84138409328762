import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 120px 0 40px 0;
  margin: 0 auto; 

  .custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		border-radius: 10px;
		border-width: 2px;
		margin: 80px auto 40px auto;

		@media (max-width: 576px) {
			width: 80%;
		}
  }
  .item-form {
    > div {
      display: flex;
      flex-direction: column; 
      >  label {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;

  }
`;