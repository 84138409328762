import React from 'react';

import { Styled } from './styled';
import BackArrow from '../../assets/icons/ic_atras.svg';
import VioletDots from '../../assets/icons/Fondo_puntos_violet2.svg';
import PinkDots from '../../assets/icons/Fondo_puntos_pink3.svg';

const Privacy: React.FC = () => (
  <Styled id="privacy">
    <div className="page-title">
			<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
			<h2>Política de Protección de datos de clientes</h2>
			<span className="hide-on-med-and-down" />
		</div>

    <img className="pink-dots dots-grid" src={PinkDots} alt="puntos rosa" />
	  <img className="violet-dots dots-grid" src={VioletDots} alt="puntos violeta" />

    <p>La protección de datos es muy importante para INTEGRA SOLUTIONS S.A. DE C.V. por eso todos nuestros clientes puedan tener la certeza que vigilamos la información que manejamos de cada uno de ellos está cifrada con software alta mente cifrados y no es proporcionada a ningún otra persona o empresa</p>

    <p>Principios básicos de la protección de datos</p>

    <p>Como empresa tenemos principios básicos que complementados con otros estándares y medidas facilitan la protección de la privacidad.</p>

    <ul>
      <li>
        <p className="article">Principio de limitación en la recolección de los datos</p>
        <p>Deberán existir límites para la recogida de datos personales y cualquiera de estos datos deberán obtenerse con medios legales y justo y, siempre que sea apropiado, con el conocimiento o consentimiento del sujeto implicado.</p>
      </li>

      <li>
        <p className="article">Principio de calidad de los datos</p>
        <p>Los datos personales deberán ser relevantes para el propósito de su uso y, en la medida de lo necesario para dicho propósito, exactos, completos y actuales.</p>
      </li>

      <li>
        <p className="article">Principio de especificación del propósito</p>
        <p>El propósito de la recogida de datos se deberá especificar a más tardar en el momento en que se produce dicha recogida, y su uso se verá limitado al cumplimiento de los objetivos u otros que no sean incompatibles con el propósito original, especificando en cada momento el cambio de objetivo.</p>
      </li>

      <li>
        <p className="article">Principio de limitación de uso</p>
        <p>No se deberá divulgar, poner a disposición o usar los datos personales para propósitos que no cumplan lo expuesto en el principio anterior, excepto si se tiene el consentimiento del sujeto implicado o por imposición legal o de las autoridades (por ejemplo, se puede disponer que los datos recopilados con fines de toma de decisiones administrativas puedan estar disponibles para investigación, estadísticas y planificación social).</p>
      </li>

      <li>
        <p className="article">Principio de salvaguardia de la seguridad</p>
        <p>Se emplearán salvaguardas razonables de seguridad para proteger los datos personales contra riesgos, tales como pérdida, acceso no autorizado, destrucción, uso, modificación o divulgación de estos. Los aspectos de seguridad y privacidad no son idénticos. Las limitaciones de uso y divulgación de los datos debieran ser reforzadas medidas de seguridad:  organizacionales; en particular, en los sistemas informáticos.</p>
      </li>

      <li>
        <p className="article">Principio de transparencia</p>
        <p>Deberá existir una política general sobre transparencia en cuanto a evolución, prácticas y políticas relativas a datos personales. Se deberá contar con medios ágiles para determinar la existencia y la naturaleza de datos personales, el propósito principal para su uso y la identidad y lugar de residencia habitual de quien controla esos datos.</p>
      </li>

      <li>
        <p className="article">Principio de responsabilidad</p>
        <p>Sobre todo, controlador de datos debe recaer la responsabilidad del cumplimiento de las medidas que hagan efectivos los principios señalados anteriormente.</p>
      </li>
    </ul>
  </Styled>
);

export default Privacy;