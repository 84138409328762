import styled from 'styled-components';

export const Styled = styled.div`
	width: 100%;
	padding: 20px 40px 80px 40px;
	margin: 80px 0;

	h2 {
		text-align: center;
	}

	.main-label {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 28px;
		margin-left: 0 !important;
	}

	.dropdown-trigger {
		height: 65px !important;
		padding-left: 20px !important;
	}

	label {
		color: #0f0f0f;
		font-size: 14px;
	}

  p {
    padding: 0;
    margin: 0 0 20px 0;
	}
	
	.custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		width: 380px;
		border-radius: 10px;
		border-width: 2px;
		margin: 80px auto 40px auto;

		@media (max-width: 576px) {
			width: 80%;
		}
	}
`;
