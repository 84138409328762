import React from 'react';

import { Styled } from './styles';
import BackArrow from '../../assets/icons/ic_atras.svg';
import VioletDots from '../../assets/icons/Fondo_puntos_violet2.svg';
import PinkDots from '../../assets/icons/Fondo_puntos_pink3.svg';

const FAQ: React.FC = () => <Styled id="faq">
	<div className="page-title">
		<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
		<h2>Preguntas frecuentes</h2>
		<span className="hide-on-med-and-down" />
	</div>

	<img className="pink-dots dots-grid" src={PinkDots} alt="puntos rosa" />
	<img className="violet-dots dots-grid" src={VioletDots} alt="puntos violeta" />

	<h3>¿Qué es QuickFix?</h3>
	<p>Somos una plataforma de multiservicios que ofrece soluciones inmediatas en las áreas de auxilio vial, fontanería, electricidad, aire acondicionado, house keeping y más</p>

	<h3>¿Debo registrarme para usar QuickFix?</h3>
	<p>No es necesario registrarte como usuario para poder adquirir nuestros servicios.</p>

	<h3>¿Método de Pago?</h3>
	<p>QuickFix te facilita la adquisición de servicio por medio del método que tu prefieras ya sea efectivo o tarjeta de crédito o débito.</p>

	<h3>¿Cómo me aseguro que mi información crediticia es protegida de forma segura?</h3>
	<p>Durante el pago de tus servicios seras redireccionado al portal web del banco para poder realizar tu pago, esto te garantiza total transparencia, seguridad y agilidad al momento de tu transferencia.</p>

	<h3>¿Qué hay sobre cobertura de servicios?</h3>
	<p>QuickFix proporciona cobertura a nivel nacional, con excepción de zonas vulnerables.</p>

	<h3>¿El servicio posee garantía?</h3>
	<p>Sí, Uno de los principios más importantes para QuickFix es la calidad, todos nuestros servicios poseen garantía de acuerdo a su categoría, puedes consultar esta información a detalle durante tu compra o enviar un correo a <a href="mailto:servicioalcliente@quickfixca.com" target="_blank" rel="noopener noreferrer">servicioalcliente@quickfixca.com</a></p>

	<h3>¿Cómo puedo reportar un problema?</h3>
	<p>Puedes escribirnos a <a href="mailto:soporte@quickfixca.com" target="_blank" rel="noopener noreferrer">soporte@quickfixca.com</a></p>

	{/* <section className="covid-section">
		<div>
			<h3>COVID BOLETÍN</h3>

			<p>En QuickFix nos preocupamos por ti por lo que:</p>

			<ul>
				<li>Todo el personal que trabaja con nosotros ha recibido capacitación en relación al manejo y prevención del COVID-19.</li>
				<li>Te proporcionamos soluciones de pago en línea para evitar contacto.</li>
				<li>Todo nuestro personal es provisto con mascarilla, alcohol gel y material desinfectante para limpiar superficies antes y después de su servicio para tu seguridad.</li>
				<li>Respetamos el distanciamiento social.</li>
			</ul>

			<p>Reporta cualquier anomalía a <a href="mailto:servicioalcliente@quickfixca.com" target="_blank" rel="noopener noreferrer">servicioalcliente@quickfixca.com</a> ¡juntos saldremos adelante!</p>
		</div>
	</section> */}
  </Styled>;
  
  export default FAQ;