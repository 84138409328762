import axios from 'axios';

// TOKEN GETS REMOVED FROM STORAGE ON LOGOUT OR ERRORS: 401 - 403
const TOKEN = window.localStorage.getItem('Bearer') || ''

// THIS CHECKS IF THE TOKEN EXIST IN THE LOCALSTORAGE, IF IT DOESN'T IT PASSES THE HEADERS WITHOUT THE AUTHORIZATION KEY
const headers = TOKEN.length > 0 ? {
  Accept: 'application/json, text/plain, */*',
  Authorization: `Bearer ${TOKEN}`
} : {
  Accept: 'application/json, text/plain, */*'
}

const instance = axios.create({
  baseURL: 'https://quickfix-api.herokuapp.com/api/v1/',
  headers
});

export default instance;