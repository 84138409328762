import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 120px 0 40px 0;
  margin: 0 auto;

  p {
    text-align: justify;
    margin-top; 40px;
  }

  .service-row {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    border-bottom: 1px solid #232323;
    transition: .3s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #acacac;
      color: #ffffff;
      transition: .3s;
    }
  }
`;