import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
	padding: 20px 40px 80px 40px;
  margin: 80px auto;

  @media (max-width: 768px) {
    width: 95%;
  }

  .custom-button {
		display: block;
		font-size: 21px;
    font-weight: 700;
    text-align: center;
		padding: 16px;
		width: 380px;
		border-radius: 10px;
    border-width: 2px;
		margin: 0 auto 40px auto;

		@media (max-width: 576px) {
			width: 80%;
		}
	}

  .address-list {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      align-items: center;
      padding-left: 16px;
      height: 80px;
      border-bottom: 1px solid #232323;
      transition: .3s;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #acacac;
        color: #ffffff;
        transition: .3s;
      }
    }
  }
`;