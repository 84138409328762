import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AxiosInstance from '../../API/axiosInstance';
import { Styled } from './styled';

import Rating from 'react-rating';

import star from './star-full.png'
import emptyStar from './star-empty.png'

interface ISurvey {
  speed: number;
  customerSupport: number;
  technicalService: number;
  trustedStore: number;
  payment: number;
}

const Survey: React.FC = () => {
  let history = useHistory();
  const pathName = window.location.pathname
  const serviceID = pathName.split('/')[pathName.split('/').length - 2]

  const initialState = {
    speed: 0,
    customerSupport: 0,
    technicalService: 0,
    trustedStore: 0,
    payment: 0,
  }

  const [form, setForm] = useState<ISurvey>(initialState)

  const handleSubmit = () => {
    if (Object.values(form).includes(0)) {
      alert("Por favor selecciona todos los campos")
    } else {
      AxiosInstance.post(`/services/${serviceID}/survey`, {
        ...form
      })
        .then((response: any) => {
          if (response.status === 200) {
            alert("Gracias por completar la encuesta")
            history.push("/profile")
          }
        })
        .catch((error: any) => {
          const { response = {} } = error;
          const { data } = response;
          alert("Error info: " + data[0]?.description)
        })
    }
  }

  return (
    <Styled>
      <div className="page-title">
        <h4>Encuesta</h4>
        <span className="hide-on-med-and-down" />
      </div>

      <div className="row item-form">
        <div className="col m12 s12">
          <label htmlFor="speed">Rapidez del servicio<span className="required-field">*</span></label>
          <Rating
            emptySymbol={<img alt="star" src={emptyStar} />}
            fullSymbol={<img alt="star" src={star} />}
            initialRating={form.speed}
            onChange={(rate) => setForm({ ...form, speed: rate })} />

        </div>
      </div>
      <div className="row item-form">
        <div className="col m12 s12">
          <label htmlFor="customerSupport">Experiencia con el Agente de Atención al cliente<span className="required-field">*</span></label>
          <Rating
            emptySymbol={<img alt="star" src={emptyStar} />}
            fullSymbol={<img alt="star" src={star} />}
            initialRating={form.customerSupport}
            onChange={(rate) => setForm({ ...form, customerSupport: rate })} />

        </div>
      </div>
      <div className="row item-form">
        <div className="col m12 s12">
          <label htmlFor="technicalService">Califique la calidad del servicio realizado por el técnico<span className="required-field">*</span></label>
          <Rating
            emptySymbol={<img alt="star" src={emptyStar} />}
            fullSymbol={<img alt="star" src={star} />}
            initialRating={form.technicalService}
            onChange={(rate) => setForm({ ...form, technicalService: rate })} />

        </div>
      </div>

      <div className="row item-form">
        <div className="col m12 s12">
          <label htmlFor="trustedStore">Confianza y Uso de la Tienda en línea o Website<span className="required-field">*</span></label>
          <Rating
            emptySymbol={<img alt="star" src={emptyStar} />}
            fullSymbol={<img alt="star" src={star} />}
            initialRating={form.trustedStore}
            onChange={(rate) => setForm({ ...form, trustedStore: rate })} />

        </div>
      </div>

      <div className="row item-form">
        <div className="col m12 s12">
          <label htmlFor="payment">¿Cómo calificas la opción de servicio de QuickFix pago en tarjeta?<span className="required-field">*</span></label>
          <Rating
            emptySymbol={<img alt="star" src={emptyStar} />}
            fullSymbol={<img alt="star" src={star} />}
            initialRating={form.payment}
            onChange={(rate) => setForm({ ...form, payment: rate })} />

        </div>
      </div>
      <div className="buttons">
        <button className="custom-button enabled-button col m3 s8 offset-m2 offset-s2" onClick={handleSubmit}>
          Enviar
          </button>
        <button onClick={() => history.push('/profile')} className="custom-button enabled-button col m3 s8 offset-m2 offset-s2">
          Cancelar
          </button>
      </div>
    </Styled >
  )
}

export default Survey;