import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import AxiosInstance from '../../API/axiosInstance';
import { formatter } from '../../lib/formatter';
import { Styled } from './styled';

import DeleteIcon from '../../assets/icons/ic_borrar.svg';
import { IProfileData } from './types';


const Profile: React.FC = () => {
  const [profileDetails, setProfileDetails] = useState<IProfileData>();
  const [success, setSuccess] = useState<Boolean | string>(false);
  const [error, setError] = useState(false);
  let history = useHistory();

  useEffect(() => {
    // Check if the "success" param exists in the URL
    setSuccess(String(window.location).split('?')[1])
    setTimeout(() => setSuccess(false), 3000)
    AxiosInstance.get('/me')
      .then(response => setProfileDetails(response.data))
      .catch(error => {
        console.error(error);
        setError(true);
        (error.response.status === 401 || error.response.status === 403) ? history.push('/login') : setTimeout(() => setError(false), 3000)
      })
  }, [history])

  const deleteAddress = (addressId: string) => {
    const c = window.confirm("¿Estás seguro que quieres elminar esta dirección?");
    if (c) {
      AxiosInstance.delete('/addresses/' + addressId)
        .then(response => {
          if (response.status === 204) {
            window.location.href = "/profile"
          }
        })
    }
  }

  const handleLogout = () => {
    const confirm = window.confirm("¿Estás seguro que deseas cerrar sesión?")
    if (confirm) {
      window.localStorage.removeItem('Bearer')
      history.push('/')
    }
  }

  return (
    <Styled id="profile">
      { error && <span className="error-msg">¡Lo sentimos! Hubo un error obteniendo la información.</span>}
      { success && <span className="error-msg success-msg">Información guardada con éxito.</span>}

      <h2>Mi Perfil</h2>

      <div className="header-with-options">
        <h3>Información personal</h3>
        <Link className="custom-button" to='/profile/edit'>Editar perfil</Link>
      </div>

      <section className="info-display row">
        <div className="col s12 m6">
          <p className="inner-header">Nombre</p>
          <p><span>{profileDetails?.firstName}</span> <span>{profileDetails?.lastName}</span></p>
        </div>

        <div className="col s12 m6">
          <p className="inner-header">Correo electrónico</p>
          <p>{profileDetails?.email}</p>
        </div>
      </section>

      <div className="header-with-options">
        <h3>Mis direcciones</h3>
        <Link className="custom-button" to='/addresses'>Direcciones</Link>
      </div>

      <section className="info-display">
        {profileDetails?.addresses.map((address, index) => (
          <div className="address-row" key={index}>
            <p className="inner-header">
              {address.name}
              <a href={`/address/edit/${address.id}`}>Edit</a>
              <span onClick={() => deleteAddress(address.id)}><img className="address-action" src={DeleteIcon} alt="eliminar" /></span>
            </p>
            <p>{address.address}</p>
          </div>
        ))}
      </section>

      <h3>Historial</h3>
      <section className="info-display">
        {profileDetails?.services.map((service, index) => (
          <Fragment key={index}>
            <div className="service-history">
              {service.serviceTypes.map(type => <p className="inner-header" key={type.id}>{type.title}</p>)}
              <p>{service.visitDate}</p>
              <p>{formatter.format(service.amount)}</p>
              <a href={`/service/${service.id}/survey`}>ENCUESTA</a>
            </div>
            <hr />
          </Fragment>
        ))}
      </section>

      <button
        onClick={handleLogout}
        className="custom-button">Cerrar sesión</button>
    </Styled>
  )
}

export default Profile;