import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AxiosInstance from '../../../API/axiosInstance';
import { Styled } from '../styled';

const VerifyPassword = () => {
  const initialPasswords = {
    password: '',
    confirm_password: ''
  }
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [passwordForm, setPasswordForm] = useState(false)
  const [passwords, setPasswords] = useState(initialPasswords)
  let history = useHistory();
  const differents = passwords.password !== passwords.confirm_password
  const lessEight = passwords.password.length < 8;
  const cannotSend = [differents, lessEight].some((v) => v === true)

  const resetPass = () => {
    AxiosInstance.get(`/users/check_reset_password_token?reset_password_token=${code}`)
      .then((response) => {
        if (response.status === 200) {
          setPasswordForm(true)
          return;
        }

        console.error(response);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      })
  }

  const handleResetPassword = () => {
    if (passwords) {
      const data = {
        ...passwords,
        reset_password_token: code
      }

      AxiosInstance.put(`/users/password`, data)
        .then((response) => {
          if (response.status === 200) {
            alert("Passwords cambiadas")
            history.push('/login')
            return;
          }

          console.error(response);
        })
        .catch((error) => {
          console.error(error, "<<< error");
          setError(true);
          setTimeout(() => setError(false), 5000);
        })
    }


  }

  const passwordFormView = () => {
    return (
      <>
        <h1>Cambia tu contraseña</h1>
        <p>Ingresa una nueva contraseña</p>
        <div className="input-without-icon">
          <input
            id="recover-password-input"
            required
            type="password"
            placeholder="Nueva contraseña"
            onChange={({ target }) => {
              setPasswords({
                ...passwords,
                password: target.value
              })
            }}
          />
          <input
            id="recover-password-input"
            required
            type="password"
            placeholder="Confirmar contraseña"
            onChange={({ target }) => {
              setPasswords({
                ...passwords,
                confirm_password: target.value
              })
            }}
          />
        </div>
        {!cannotSend && <button onClick={handleResetPassword} className="custom-button">Enviar</button>}
      </>
    )
  }

  return (
    <Styled className="access-wrapper new-pass">
      { error && <span className="error-msg">¡Lo sentimos! Código probablemente esté incorrecto</span>}
      {passwordForm ? passwordFormView() :
        <>
          <h1>Verifica tu código</h1>
          <p>Ingresa tu el código que te enviamos a tu correo</p>
          <div className="input-without-icon">
            <input
              id="recover-password-code"
              required
              type="text"
              value={code}
              placeholder="Código de validación"
              onChange={({ target }) => {
                setCode(target.value)
              }}
            />
          </div>
          <button onClick={resetPass} className="custom-button">Enviar</button>
        </>
      }
      <a href="/forgot-pass" className="center">Aún no tengo mi código</a>
    </Styled>
  )
};

export default VerifyPassword;