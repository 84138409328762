import styled from 'styled-components';

export const Styled = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 25px;
  }

  .links-wrapper {
    div {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
    
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 0 10px;
      }

      .styled-link {
        background: none;
        border: none;
        padding: 0;

        &:nth-child(2n)::before, &:nth-child(2n)::after {
          content: '|';
          font-size: 24px;
        }

        @media (max-width: 768px) {
          &:nth-child(2n)::before, &:nth-child(2n)::after {
            content: '';
          }

          padding: 10px 0;
        }
    
        a {
          display: inline-block;
          position: relative;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          letter-spacing: 1px;
          padding: 0 15px;
        }
      
        a::before {
          --transition: all .5s cubic-bezier(1,.25,0,.75) 0s;
      
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          bottom: -3px;
          left: 0;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: var(--transition);
          transition: var(--transition);
        }
      
        a:hover::before {
          visibility: visible;
          background-color: #ffffff;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }

  #copyright {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    @media (max-width: 1024px) {
      order: 3;
    }
  
    p {
      text-align: center;
    }
  }

  .social-icons {
    @media (max-width: 1024px) {
      margin: 20px 0;
    }

    a {
      mergin-right: 15px;
      margin-left: 15px;
    }
  }
`;