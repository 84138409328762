import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 120px 0 40px 0;
  margin: 0 auto;

  label {
    padding-left: 16px;
  }

  .custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		border-radius: 10px;
		border-width: 2px;
		margin: 80px auto 40px auto;

		@media (max-width: 576px) {
			width: 80%;
		}
	}

	.footer-action {
		margin-top: 40px;
		
		.custom-button {
			margin-top: 20px;
		}
	}
`;