import styled from 'styled-components';

export const Styled = styled.div`
	.fab {
			position: fixed;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 70px;
			width: 70px;
			right: 50px;
			bottom: 50px;
			z-index: 10;
			transition: .3s;

			&:hover {
				transition: .3s;
			}

			@media (max-width: 576px) {
				right: 20px;
				bottom: 20px:
			}
	}

	.fab > span {
			position: absolute;
			top: 7px;
			font-size: 0.8em;
	}
`