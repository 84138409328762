import styled from 'styled-components';

export const Styled = styled.div`
	min-height: 100vh;
	width: 100%;
	padding-top: 80px;

	#container {
		h1, p, .forgot-pass {
			text-align: center;
		}

		input {
			&::placeholder {
				opacity: 0.7;
			}
		}

		.forgot-pass {
			display: block;
			margin-top: 40px;
			margin-bottom: 40px;
		}

		.wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			min-height: 600px;
			padding: 0 40px;

			@media (max-width: 768px) {
        flex-direction: column;
				padding: 0 10px;
      }
		}

		.info-panel {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 600px;
			padding: 0 40px
		}
	}

	.custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		width: 380px;
		border-radius: 10px;
		border-width: 2px;
		margin: 40px auto;
		transition: .3s;

		&:hover {
			transition: .3s;
		}

		@media (max-width: 576px) {
			width: 80%;
		}
	}

	h1, p {
		text-align: center;
	}

	.input-with-icon {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			padding-right: 16px;
		}
	}

	label {
		color: white;
		font-size: 14px;
	}

	#recover-password-email {
		width: 80%;
	}
	.input-without-icon{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#recover-password-code {
		width: 50%;
		height: 80px;
		font-size: 22px; 
	}
	#recover-password-input {
		width: 50%;
		font-size: 18px; 
		margin-top: 10px;
	}
	> .center {
		display: block;
		text-align: center;
	}
`;
