import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 60px 0 40px 0;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  h3 {
    font-weight: 700;
  }

  p {
    text-align: justify;
    margin-top; 40px;
  }

  .header-with-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  .info-display {
    padding: 20px 0;

    .inner-header {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    p {
      margin-top: 0;

      a {
        margin-left: 40px;
      }
    }

    .address-row {
      margin-bottom: 40px;
    }

    .service-history {
      padding: 24px 0;
    }

    .address-action {
      height: 20px;
      margin-left: 56px;
    }
  }

  .custom-button {
    display: block;
    font-size: 21px;
    font-weight: 700;
    padding: 16px 32px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    transition: .3s;

    &:hover {
      transition: .3s;
    }

    @media (max-width: 576px) {
      padding: 8px 16px;
      max-width: 80%;
    }
  }
`;