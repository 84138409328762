import React, { useState, createContext } from 'react';

interface FormFields {
  name: string;
  email: string;
  phone: string;
  addressId: string;
  lat: string;
  lon: string;
  dropAddressId: string;
  direction: string;
  dropLat: string;
  dropLon: string;
  amount: string;
  carBrand: string;
  carModel: string;
  subject: string;
  numberPlate: string;
  paymentType: string;
  distance: string;
  visitDate: string;
  wazeLink: string;
  gmapsLink: string;
  [key: string]: string;
}

interface Props {
  formState: FormFields;
  setFormState: Function;
};

export const FormContext = createContext<Partial<Props>>({});

export const FormProvider: React.FC = ({ children }) => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    addressId: '',
    lat: '',
    lon: '',
    dropAddressId: '',
    dropLat: '',
    dropLon: '',
    carBrand: 'Honda',
    carModel: '',
    numberPlate: '',
    paymentType: '',
    distance: '',
    visitDate: '',
    wazeLink: '',
    gmapsLink: '',
    amount: '',
    subject: '',
    direction: ''
  })
  const value = { formState, setFormState }
  return (
    <FormContext.Provider value={value}>
      { children}
    </FormContext.Provider>
  )
}
