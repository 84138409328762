import AxiosInstance from './axiosInstance';

export const getAddresses = (set, history) => {
	AxiosInstance.get('/addresses')
		.then(res => {
			const ADDRESS_OBJ = res.data.map((address) => ({ id: address.id, name: address.name, lat: address.lat, lng: address.lon }))
			set(ADDRESS_OBJ)
		})
		.catch(error => {
			console.error(error);
			if (error.response.status === 401 || error.response.status === 403) {
				history && history.push('/login')
			}
		})
}