import React, { useContext } from 'react';

import cart from "../../assets/icons/shopping-cart.svg";
import { CartContext } from '../../context/Cart.context';
import { Styled } from "./styled";

const FAB = () => {
	const cartValue = useContext(CartContext);

	return (
		<Styled className={cartValue.cartState?.length === 0 ? 'hide-on-large-only hide' : 'hide-on-large-only'}>
			<a href="checkout" className="btn-floating btn-large fab">
				<img src={cart} alt="shopping cart" />
				<span>{cartValue && cartValue.cartState?.length}</span>
			</a>
		</Styled>
	)
};

export default FAB;
