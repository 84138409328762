import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
	margin: 80px 0;
	
	.page-title {
		width: 80%;
		margin: 0 auto;

		@media (max-width: 768px) {
			width: 95%;
		}
	}

  h2 {
		font-weight: 900;
    text-align: center;
    text-transform: capitalize;
  }

  p {
		font-size: 24px;
		text-align: center;
		margin: 70px 0;
  }

  .selected-items {
    h5 {
			text-align: left;
			font-size: 17px;
			font-weight: 700;
      margin-top: 80px;
			margin-left: 0;
    }

    ul {
      li {
        display: flex;
				align-items: center;
				flex-direction: row;
				font-size: 16px;
				font-weight: 500;
				justify-content: space-between;
				border-bottom: 1px solid #afafaf;
				padding 25px 0;

        button {
					padding: 14px 30px;
					border-radius: 10px;
					border-width: 2px;
					border-style: solid;
					transition: .3s;

					&:hover {
						transition: .3s;
					}

					@media (max-width: 576px) {
						padding: 8px 12px;
					}
        }
			}
		}

		.additional-charge {
			font-size: 14px;
			text-align: start;
			margin: 20px 0 40px 0;
		}

		.total {
			text-align: right;
			font-size: 20px;
			font-weight: 700;
		}
	}
`;