import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Styled from './styled';
import { MenuContext } from '../../context/Menu.context';

interface Props extends RouteComponentProps {}

const Wrapper: React.FC<Props> = ({history, children}) => {
	const [target] = useState('');
	const menuLatestTarget = useContext(MenuContext);
	const { setMenuState } = menuLatestTarget;

	useEffect(() => {
		setMenuState && setMenuState({target})
	}, [setMenuState, target])

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (
    <Styled onClick={() => setMenuState && setMenuState({target: ''})}>
      {children}
    </Styled>
  )
}

export default withRouter(Wrapper);