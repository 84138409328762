import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuContext } from '../../context/Menu.context';
import { CartContext } from '../../context/Cart.context';
import { Menu, Wrapper } from './styled';

import MenuIcon from '../../assets/icons/menu.svg';
import LogoDark from '../../assets/images/logo-dark-bg.png';
import Logo from '../../assets/images/logo.png';
import Cart from '../../assets/icons/shopping-cart.svg';
import CartBlue from '../../assets/icons/ic_cart_blue.svg';
import BackArrow from '../../assets/icons/ic_atras.svg';
import Profile from '../../assets/icons/ic_perfil.svg';

const HAS_TOKEN = window.localStorage.getItem('Bearer') || false;

const Header: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const menuLatestTarget = useContext(MenuContext);
  const cartValue = useContext(CartContext);
  const { menuState, setMenuState } = menuLatestTarget;

  useEffect(() => {
    if (menuState) {
      if (menuState.target === '') setVisible(false);
    }
  }, [menuState, visible])

  const menuTrigger = () => {
    setVisible(!visible);
    setMenuState && setMenuState({ target: 'visible' });
  }

  return (
    <Wrapper id="navbar">
      { (window.location.pathname === '/' || window.location.pathname.split('/').indexOf('confirmation') >= 0) ? (
        <div>
          <button className="hide-on-large-only menu-button" onClick={menuTrigger}>
            <img src={MenuIcon} alt="mobile menu" />
          </button>

          <a className="logo" href='/'>
            <img src={LogoDark} alt="Quickfix CA" />
          </a>

          <Menu className="hide-on-med-and-down" id="desktop-menu">
            <li className="menu-option">
              <Link to='/nosotros' onClick={() => setVisible(!visible)}>¿Quienes Somos?</Link>
            </li>
            <li className="menu-option">
              <Link to='/faq' onClick={() => setVisible(!visible)}>Preguntas Frecuentes</Link>
            </li>
            <li>
              <span className="social-icons">
                {HAS_TOKEN ? (
                  <>
                    <img src={Profile} alt="logo de usuario" />
                    <Link to='/profile' onClick={() => setVisible(!visible)}>Perfil</Link>
                  </>
                ) : (
                    <>
                      <img src={Profile} alt="logo de usuario" />
                      <Link to='/login' onClick={() => setVisible(!visible)}>Iniciar Sesión</Link>
                    </>
                  )}
              </span>
            </li>
            <li className={cartValue.cartState?.length === 0 ? 'hide' : 'checkout-cart'}>
              <a href="/checkout">
                <span>{cartValue && cartValue.cartState?.length}</span>
                <img src={Cart} alt="Revisa tu carrito de compras" />
              </a>
            </li>
          </Menu>
        </div>
      ) : (
          <div className="light-bar">
            <a className="hide-on-large-only" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
            <a className="logo" href='/'>
              <img src={Logo} alt="Quickfix CA" />
            </a>
            <span className="hide-on-large-only" />

            <Menu className="hide-on-med-and-down">
              <li className={cartValue.cartState?.length === 0 ? 'hide' : 'checkout-cart'}>
                <a href="/checkout">
                  <span>{cartValue && cartValue.cartState?.length}</span>
                  <img src={CartBlue} alt="Revisa tu carrito de compras" />
                </a>
              </li>
            </Menu>
          </div>
        )}
      { visible && (
        <Menu className="hide-on-large-only" id="mobile-menu">
          <li>
            <Link to='/' onClick={() => setVisible(!visible)}>Inicio</Link>
          </li>
          <li>
            <Link to='/nosotros' onClick={() => setVisible(!visible)}>¿Quienes Somos?</Link>
          </li>
          <li>
            <Link to='/faq' onClick={() => setVisible(!visible)}>Preguntas Frecuentes</Link>
          </li>
          <li>
            <span className="social-icons icons-mobile">
              {HAS_TOKEN ? (
                <Link to='/profile' onClick={() => setVisible(!visible)}>Perfil</Link>
              ) : (
                  <Link to='/login' onClick={() => setVisible(!visible)}>Iniciar Sesión</Link>
                )}
            </span>
          </li>
        </Menu>
      )}
    </Wrapper>
  )
}

export default Header;