import styled from 'styled-components';

import CarAssistance from '../../assets/images/asistencia_vial.svg';
import Electricity from '../../assets/images/reparaciones_electricas.svg';
import Key from '../../assets/images/cerrajeria.svg';

export const Styled = styled.div`
	width: 100%;

	.dots-grid {
		position: absolute;
		width: 300px;
		z-index: 1;
	}

	#right {
		right: 0
	}

	#left {
		top: 50vh;
		left: 0;
	}

	#center {
		left: 40px;
		top: 100%;
		width: 60%;
	}

	// used important here because there's another rule for image defined below
	.service-floating-icon {
		position: absolute;
		max-height: 160px !important;
		z-index: 10;
	}

	.top-left {
		top: 30px;
		left: 70px;
	}

	.top-right {
		top: 120px;
		right: 40px;
	}

	.bottom-left {
		top: 480px;
		left: 40px;
	}

	.bottom-right {
		top: 560px;
		right: -30px;
	}

  .above-the-fold {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column-reverse;
		}
		
		#banner-info {
			z-index: 2;

			section {
				max-width: 800px;
				margin: 0 auto;

				@media (max-width: 768px) {
					padding: 20px;
				}

				h1 {
					text-align: center;
					font-weight: 900;
					line-height: 72px;
					padding-top: 100px;
				}

				p {
					font-size: 16px;
					text-align: center;
				}
	
				button {
					height: 60px;
					font-size: 17px;
					font-weight: 700;
					text-align: center;
					border: none;
					border-radius: 5px;
					padding: 12px 55px;
				}

				@media (max-width: 768px) {
					h1 {
						line-height: 48px;
						padding-top: 0;
					}

					p, button {
						width: 100%;
					}
				}
			}
		}

		img {
			margin-top: 80px;
			max-height: 575px;
			height: auto;
		}
	}

	.services-title {
		padding-top: 90px;
		margin-bottom: 80px;
	}


	.how-it-works {
		padding: 40px 0;

		#scroll-indicator {
			display: block;
			margin: 0 auto;
		}

		h2 {
			text-align: center;
			padding-top: 20px;
		}

		p {
			text-align: center;
		}

		#service-steps {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: no-wrap;
			justify-content: space-evenly;
			width: 50%;
			margin: 0 auto;
			z-index: 2;

			@media (max-width: 756px) {
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;
				width: 100%;
			}

			.service-row {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 180px;
				margin: 40px 28px 40px 28px;

				img {
					height: 50px;
					margin-bottom: 25px;
				}

				p {
					font-size: 15px
					text-align: center;
				}
			}
		}
	}

	.services-grid {
		section {
			display: flex;

			div {
				width: 50%;
				height: 475px;

				@media (max-width: 600px) {
					width: 100%;	
				}
			}

			.image-background {
				background-size: cover;
				background-position: right;
			}

			.service-detail {
				position: relative;
				padding-top: 50px;
				padding-right: 40px;
				padding-bottom: 100px;

				.detail-image {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					height: inherit;
					width: calc(110px + (270 - 110) * ((100vw - 300px) / (1600 - 300)));
				}

				h3 {
					font-weight: 700;
				}

				p {
					font-weight: 500;
					text-align: left;
				}

				a {
					display: inline-block;
					padding: 12px 30px;
					border-radius: 4px;
					border-width: 2px;
					border-style: solid;
					margin-top: 30px;
					transition: .3s;

					&:hover {
						transition: .3s;
					}
				}
			}
	
			.electricity {
				background-image: url(${Electricity});
			}

			.car-assistance {
				background-image: url(${CarAssistance});
			}

			.key {
				background-image: url(${Key});
			}
		}
	}

	.join-us {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;

		@media (max-width: 800px) {
			flex-direction: column;
		}

		img {
			height: 500px;

			@media (max-width: 756px) {
				display: block;
				height: auto;
				width: 80%;
				margin: 0 auto;
			}
		}

		div {
			max-width: 800px;
			padding: 50px 80px 100px 80px;

			@media (max-width: 768px) {
				padding: 20px;
			}

			h2 {
				font-size: 48px;
				line-height: 65px;
			}

			p {
				font-weight: 400;
				font-size: 16px;
			}

			a {
				display: inline-block;
				font-size: 17px;
				padding: 14px 50px;
				border-radius: 5px;
				border-width: 2px;
				border-style: solid;
				margin-top: 20px;
				transition: .3s;

				@media (max-width: 576px) {
					width: 100% !important;
					text-align: center;
				}

				&:hover {
					transition: .3s;
				}
			}
		}
	}
`;