import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { FormProvider } from './context/Form.context';
import { CartProvider } from './context/Cart.context';
import { MenuProvider } from './context/Menu.context';

import Homepage from './views/Homepage';
import AboutUs from './views/AboutUs';
import Privacy from './views/Privacy';
import Checkout from './views/Checkout';
import Terms from './views/Terms';
import FAQ from './views/FAQ';
import JoinUs from './views/JoinUs';
import Authentication from './views/Authentication';
import ConfirmationPage from './views/ConfirmationPage';
import Profile from './views/Profile';
import EditProfile from './views/Profile/Edit';
import History from './views/History';
import Address from './views/Address';
import EditAddress from './views/Address/Edit';
import ServiceInternal from './views/ServiceInternal';
import ForgotPassword from './views/Authentication/ForgotPassword';
import VerifyPassword from './views/Authentication/ForgotPassword/VerifyPassword';

import Wrapper from './components/Wrapper';
import Header from './components/Header';
import Footer from './components/Footer';
import FAB from './components/FAB';
import Survey from './views/Survey';

const HAS_TOKEN = window.localStorage.getItem('Bearer') || false;

interface Props {
	children: React.ReactChild;
	path: string;
}

const PrivateRoute: React.FC<Props> = ({ children, path }) => {
	return (
		<Route path={path}
			render={() =>
				HAS_TOKEN ? (
					children
				) : (
						<Redirect
							to={{
								pathname: "/login?#",
							}}
						/>
					)
			}
		/>
	);
}

const App = () => (
	<Router>
		<MenuProvider>
			<CartProvider>
				<Header />
				<FormProvider>
					<Wrapper>
						<Switch>
							<Route exact path={["/login?#", "/login"]}>
								<Authentication />
							</Route>
							<Route path="/faq">
								<FAQ />
							</Route>
							<Route path="/privacidad">
								<Privacy />
							</Route>
							<Route path="/condiciones">
								<Terms />
							</Route>
							<Route path="/checkout">
								<Checkout />
							</Route>
							<Route path="/nosotros">
								<AboutUs />
							</Route>
							<Route path="/unete">
								<JoinUs />
							</Route>
							<Route path="/service/:id/survey">
								<Survey />
							</Route>
							<Route path="/confirmation">
								<ConfirmationPage />
							</Route>
							<Route path="/forgot-pass">
								<ForgotPassword />
							</Route>
							<Route path="/verify-password">
								<VerifyPassword />
							</Route>
							<Route path="/servicio/:id">
								<ServiceInternal />
							</Route>
							<PrivateRoute path="/profile/edit">
								<EditProfile />
							</PrivateRoute>
							<PrivateRoute path="/profile">
								<Profile />
							</PrivateRoute>
							<PrivateRoute path="/history">
								<History />
							</PrivateRoute>
							<PrivateRoute path="/address/new/:id">
								<EditAddress />
							</PrivateRoute>
							<PrivateRoute path="/address/edit/:id">
								<EditAddress />
							</PrivateRoute>
							<PrivateRoute path="/addresses">
								<Address />
							</PrivateRoute>
							<Route path="/">
								<Homepage />
							</Route>
						</Switch>
						<FAB />
					</Wrapper>
				</FormProvider>
			</CartProvider>
		</MenuProvider>
		<Footer />
	</Router>
);

export default App;
