import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 120px 0 40px 0;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  h4 {
    text-transform: capitalize;
    font-weight: 700;
    margin: 30px 0;
  }

  p {
    text-align: justify;
    margin-top; 40px;
  }
`;