import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AxiosInstance from '../../../API/axiosInstance';
import { Styled } from './styled';
import Map from '../../../components/Map';
import BackArrow from '../../../assets/icons/ic_atras.svg';
import { getAddresses } from '../../../API/getAddresses';

interface Address {
  id: string | null
  name: string
  lat: number | string
  lng: number | string
}

interface ILocation {
  lat: number;
  lng: number;
}

const EditAddress = () => {
  const [error, setError] = useState(false);
  const [addressList, setAddressList] = useState<any>([]);

  // THIS TAKES THE ID OF THE ADDRESS FROM THE URL IF THE USER CLICKED ON "EDIT"
  const { location } = window;
  const addressID = location.pathname.split('/').pop()

  // THIS EXTRACTS THE OBJECT WITH THE CURRENT ADDRESS BASED ON THE ID IN THE URL
  const editAdress = addressList.find((address: any) => address.id === addressID)
  const initState = {
    id: null,
    name: '',
    lat: addressID === 'new' ? 13.698927 : '',
    lng: addressID === 'new' ? -89.206350 : ''
  };
  let history = useHistory();

  // TO-DO: SET THE VALE OF 'newAddress' TO THE CURRENT ADDRESS IF WE ARE EDITING, IF NOT USE DEFAULT LOCATION
  const [newAddress, setNewAddress] = useState<Address>(initState)

  const locationValues = {
    lat: Number(newAddress.lat),
    lng: Number(newAddress.lng)
  }

  // TAKE THE MAP TO THE USER'S CURRENT POSITION
  const currentPosition = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => {
        setNewAddress({
          ...newAddress,
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      err => console.error(err)
    );
  };

  useEffect(() => {
    getAddresses(setAddressList, history)
  }, [setAddressList, history])

  useEffect(() => {
    editAdress && setNewAddress({
      ...editAdress,
      lat: editAdress.lat,
      lng: editAdress.lng
    })
  }, [editAdress])


  const handleSendData = () => {
    const payload = {
      ...newAddress,
      lon: newAddress.lng
    }
    if (addressID === "new") {
      AxiosInstance.post('/addresses', {
        ...payload
      }).then(() => history.push('/profile/?success=true'))
        .catch(error => {
          setError(true);
          setTimeout(() => setError(false), 3000)
        })
    } else {
      AxiosInstance.put(`/addresses/${addressID}`, {
        ...payload
      }).then(() => history.push('/profile/?success=true'))
        .catch(error => {
          setError(true);
          setTimeout(() => setError(false), 3000)
        })
    }
  };

  const handleAddress = (address: ILocation) => {
    setNewAddress({
      ...newAddress,
      lat: address.lat,
      lng: address.lng
    })
  }

  return (
    <Styled id="edit-address">
      { error && <span className="error-msg">¡Lo sentimos! Hubo un error obteniendo la información.</span>}
      <div className="page-title">
        <a className="hide-on-med-and-down" href="/addresses"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
        {addressID !== "new" ? <h2>Editar dirección</h2> : <h2>Agregar dirección</h2>}
        <span className="hide-on-med-and-down" />
      </div>

      <h3>{addressID !== 'new' ? newAddress?.name : 'Nueva dirección'}</h3>

      <label>Nombre de la dirección</label>
      <input
        value={newAddress.name}
        placeholder="Ej. Casa, Oficina"
        onChange={({ target }) => {
          setNewAddress({ ...newAddress, name: target.value })
        }}
      />

      <div className="map">
        <button
          onClickCapture={e => e.preventDefault()}
          className="custom-button add-service"
          onClick={currentPosition}>Seleccionar mi ubicación actual
        </button>
        <Map
          location={locationValues}
          destination={handleAddress}
        />
      </div>

      <div className="row footer-action">
        <button onClick={handleSendData} className="custom-button enabled-button col m3 s8 offset-m2 offset-s2">
          {addressID === "new" ? 'Guardar' : 'Editar'}
        </button>

        {addressID !== 'new' && (
          <button className="custom-button enabled-button col m3 s8 offset-m2 offset-s2">
            Eliminar dirección
          </button>
        )}
      </div>
    </Styled>
  )
}

export default EditAddress;