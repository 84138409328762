export const CATEGORIES: {
  [key: string]: {
    [key: string]: {
      id: string;
      title: string;
      price: number;
      extraKm: number;
    }
  }
} = {
  'Asistencia Vial': {
    'Grua liviana': {
			id: 'SG02GRLIV01',
      title: "Asistencia Vial - Grua Liviana de Plataforma",
      price: 32.00,
      extraKm: 0.85,
    },
    'Grua pesada 3 - 5 toneladas': {
			id: 'SG02GRPCN02',
      title: "Asistencia Vial - Grua Pesada",
      price: 55.00,
      extraKm: 1.25,
    },
    'Grua pesada 6 - 8 toneladas': {
			id: 'SG02GRPOC03',
      title: "Asistencia Vial - Grua Pesada",
      price: 70.00,
      extraKm: 1.35,
    },
    'Grua pesada 9 - 12 toneladas': {
			id: 'SG02GRPDC04',
      title: "Asistencia Vial - Grua Pesada",
      price: 74.00,
      extraKm: 1.35,
    },
    'Grua pesada 13 - 20 toneladas': {
			id: 'SG02GRPVN05',
      title: "Asistencia Vial - Grua Pesada",
      price: 77.00,
      extraKm: 1.35,
    },
    'Grua para motocicleta': {
			id: 'SG02GRPMT06',
      title: "Asistencia Vial - Grua Pesada",
      price: 20.00,
      extraKm: 0.60,
    },
    /* 'Cerrajería Vial': {
			id: 'AV01CRRVH01',
      title: "Asistencia Vial - Cerrajería Vial",
      price: 25.00,
      extraKm: 0.60,
    }, */
    'Paso de Corriente': {
			id: 'AV01PCOVH02',
      title: "Asistencia Vial - Paso de Corriente",
      price: 15.00,
      extraKm: 0.60,
    },
    'Abastecimiento de Combustible': {
			id: 'AV01ABCOM03',
      title: "Asistencia Vial - Abastecimiento de Combustible",
      price: 15.00,
      extraKm: 0.60,
    },
    'Cambio de Llanta': {
			id: 'AV01CMLLN04',
      title: "Asistencia Vial - Cambio de Llanta",
      price: 15.00,
      extraKm: 0.60,
    },
    'Envío de reparación de Llanta': {
			id: 'AV01ENRLL05',
      title: "Asistencia Vial - Envío de reparación de Llanta",
      price: 15.00,
      extraKm: 0.60,
    },
    'Apertura de Cerrajería Vehicular': {
			id: 'AV01APCVH06',
      title: "Asistencia Vial - Apertura de Cerrajería Vehicular",
      price: 25.00,
      extraKm: 0.60,
    },
    /* 'Chofer Por Evento': {
			id: 'AV01CHPEV07',
      title: "Asistencia Vial - Conductor Designado",
      price: 25.00,
      extraKm: 0.00,
    }, */
	},
	'Electricidad': {
		'Cambio de Rosetas': {
			id: 'EL02CMIRS01',
      title: "Electricista - Cambio/Instalación de Rosetas",
      price: 27.97,
      extraKm: 0,
    },
		'Cambio de Cajas Térmicas': {
			id: 'EL02CMCJT02',
      title: "Electricista - Cambio de Cajas Térmicas",
      price: 28.25,
      extraKm: 0,
    },
		'Cambio de Toma Corriente': {
			id: 'EL02CMTCI03',
      title: "Electricista - Cambio de Toma Corriente o Interruptor",
      price: 27.52,
      extraKm: 0,
    },
		'Evaluación Eléctrica': {
			id: 'EL02EVAEL04',
      title: "Electricista - Evaluación Eléctrica",
      price: 22.60,
      extraKm: 0,
    },
  },
  'Fontanería': {
    'Cambio de Chorro Convenvional': {
      id: 'FT04CMCHC01',
      title: 'Fontanería - Cambio de Chorro Convenvional',
      price: 28.25,
      extraKm: 0,
    },
    'Cambio de Chorros Especial': {
      id: 'FT04CMCHE02',
      title: 'Fontanería - Cambio de Chorros Especial',
      price: 21.47,
      extraKm: 0,
    },
    'Cambio de Empaque': {
      id: 'FT04CMEMP03',
      title: 'Fontanería - Cambio de Empaque',
      price: 22.04,
      extraKm: 0,
    },
    'Lavatrastos Tapado': {
      id: 'FT04LTRTA04',
      title: 'Fontanería - Lavatrastos Tapado',
      price: 28.25,
      extraKm: 0,
    },
    'Lavamanos Tapado': {
      id: 'FT04LVMTA05',
      title: 'Fontanería - Lavamanos Tapado',
      price: 28.25,
      extraKm: 0,
    },
    'Baño Tapado': {
      id: 'FT04BANTA06',
      title: 'Fontanería - Baño Tapado',
      price: 22.60,
      extraKm: 0,
    },
    'Reparación de Fuga de Lavatrastos': {
      id: 'FT04RPFLV07',
      title: 'Fontanería - Reparación de Fuga de Lavatrastos',
      price: 28.25,
      extraKm: 0,
    },
    'Instalación de Sifones': {
      id: 'FT04INTSI11',
      title: 'Fontanería - Instalación de Sifones',
      price: 28.25,
      extraKm: 0,
    },
    'Instalación de Grifos': {
      id: 'FT04INTGR12',
      title: 'Fontanería - Instalación de Grifos',
      price: 28.25,
      extraKm: 0,
    },
    'Cambio de Llave de Regadera Convencional': {
      id: 'FT04CMLRC14',
      title: 'Fontanería - Cambio de Llave de Regadera Convencional',
      price: 25.99,
      extraKm: 0,
    },
    'Cambio de Llave de Regadera Especial': {
      id: 'FT04CMLRE15',
      title: 'Fontanería - Cambio de Llave de Regadera Especial',
      price: 28.25,
      extraKm: 0,
    },
    'Destape de Tuberías Potables Básico': {
      id: 'FT04DSTPB16',
      title: 'Destape de Tuberías Potables Básico',
      price: 28.25,
      extraKm: 0,
    },
    'Instalación de Llaves de Paso de Agua': {
      id: 'FT04INTLP17',
      title: 'Fontanería - Instalación de Llaves de Paso de Agua',
      price: 25.99,
      extraKm: 0,
    } 
    /* 'Reparación de Abasto de Agua': {
      id: 'FT04RPABA08',
      title: 'Fontanería - Reparación de Abasto de Agua',
      price: 25.00,
      extraKm: 0.60,
    },
    'Cambio de Bomba de Abastecimiento': {
      id: 'FT04CMBMA09',
      title: 'Fontanería - Cambio de Bomba de Abastecimiento',
      price: 20.00,
      extraKm: 0.60,
    },
    'Evaluación Técnica Fontanería': {
      id: 'FT04EVAFT10',
      title: 'Fontanería - Evaluación Técnica Fontanería',
      price: 20.00,
      extraKm: 0.60,
    },
    'Tubería Obstruída de Pila Evaluación': {
      id: 'FT04EVAPO13',
      title: 'Fontanería - Tubería Obstruída de Pila Evaluación',
      price: 20.00,
      extraKm: 0.60,
    }, */
  }
};

interface CarsObj {
  [key: string]: string[]
}

export const CARS: CarsObj = {
  'Ford': ['F-Series', 'Fiesta', 'Escort', 'Focus', 'Mustang', 'Transit', 'Taurus', 'Explorer', 'LTD', 'Mondeo', 'Ranger Raptor', 'F-150 Raptor', 'S-Max', 'Galaxy', 'Puma', 'Escape', 'Edge', 'E-Series', 'Ranger'],
  'Honda': ['Accord', 'Jazz', 'Civic', 'CR-V', 'HR-V'],
  'Hyundai': ['I20', 'Ix35', 'Ix20', 'I10', 'Santa Fe', 'Veloster', 'I40', 'Elantra', 'I30', 'Grand Santa Fe', 'Genesis', 'H-1 Travel', 'Tucson', 'I20 Active', 'IONIQ', 'Kona'],
  'Jeep': ['Grand Cherokee', 'Wrangler Unlimited', 'Cherokee', 'Wrangler', 'Renegade', 'Compass'],
  'Kia': ['Picanto', 'Rio', 'Sportage', 'Venga', 'Optima', 'Cee\'d', 'Cee\'d Sportswagen', 'Carens', 'Pro Cee\'d', 'Sorento', 'Soul', 'Niro', 'Soul EV', 'Pro Cee\'d GT', 'Stonic', 'Optima SW', 'Optima PHEV', 'Optima SW GT', 'Optima GT', 'Stinger', 'Forte'],
  'Land Rover': ['Defender', 'Discovery 4', 'Range Rover', 'Range Rover Evoque', 'Freelander', 'Range Rover Sport', 'Discovery Sport', 'Discovery', 'Range Rover Velar'],
  'Lexus': ['GS', 'RX', 'CT', 'IS', 'NX', 'RC', 'LS', 'LC'],
  'Mazda': ['Mazda 2', 'CX-5', 'Mazda 6', 'MX-5', 'Mazda 3', 'Mazda 5', 'CX-9', 'CX-3'],
  'Mercedez': ['Clase SL', 'Clase SLK', 'Clase V', 'Clase C', 'Clase M', 'Clase G', 'Clase E', 'Clase CL', 'Clase S', 'Clase GLK', 'SLS AMG', 'Clase B', 'Clase A', 'Clase GL', 'Clase CLS', 'Clase CLA', 'Clase GLA', 'AMG GT', 'Vito', 'Clase GLE Coupé', 'Clase GLE', 'Clase GLC', 'Citan', 'Clase GLS', 'Clase SLC', 'GLC Coupé', 'Mercedes-AMG GT', 'CLS'],
  'Mini': ['MINI', 'COUNTRYMAN', 'PACEMAN', 'CLUBMAN'],
  'Mitsubishi': ['Montero', 'I-MiEV', 'ASX', 'Outlander', 'Space Star', 'L200', 'Eclipse Cross'],
  'Nissan': ['X-TRAIL', 'QASHQAI', 'NOTE', 'LEAF', 'Pathfinder', 'EVALIA', 'Navara', 'Micra', 'JUKE', '370Z', 'NV200', 'GT-R', 'PULSAR', 'Murano', 'NV200 EVALIA', 'E-NV200 EVALIA'],
  'Porsche': ['911', 'Boxster', 'Cayenne', 'Panamera', '918', 'Macan', 'Cayman', '718'],
  'Subaru': ['Forester', 'XV', 'Outback', 'BRZ', 'WRX STI', 'Levorg', 'WRX STI'],
  'Suzuki': ['Grand Vitara', 'Swift', 'SX4', 'Jimny', 'SX4 S-Cross', 'Celerio', 'Kizashi', 'Vitara', 'Baleno', 'Ignis'],
  'Toyota': ['Avensis', 'Land Cruiser', 'Yaris', 'Verso', 'Auris', 'Prius+', 'GT86', 'Prius', 'Rav4', 'Aygo', 'Hilux', 'Land Cruiser 200', 'Proace Verso', 'C-HR'],
  'VOLKSWAGEN': ['Polo', 'Jetta', 'Phaeton', 'Golf', 'Touran', 'Touareg', 'Beetle', 'Sharan', 'Tiguan', 'Multivan', 'California', 'Caravelle', 'Up!', 'CC', 'Golf Sportsvan', 'Amarok', 'Caddy', 'Transporter', 'Scirocco', 'Passat', 'Eos', 'Arteon', 'T-Roc', 'Tiguan Allspace'],
	'Volvo': ['V70', 'S80', 'XC70', 'V60', 'S60', 'XC90', 'XC60', 'V40', 'V40 Cross Country', 'V60 Cross Country', 'S60 Cross Country', 'S90', 'V90', 'V90 Cross Country', 'XC40'],
};

export const DEPARTMENTS: { [key: string]: string[] } = {
  'Ahuachapán': ['Ahuachapán', 'Apaneca', 'Atiquizaya', 'Concepción de Ataco', 'El Refugio', 'Guaymango', 'Jujutla', 'San Francisco Menéndez', 'San Lorenzo', 'San Pedro Puxtla', 'Tacuba', 'Turín'],
  'Cabañas': ['Sensuntepeque', 'Cinquera', 'Dolores', 'Guacotecti', 'Ilobasco', 'Jutiapa', 'San Isidro', 'Tejutepeque', 'Victoria'],
  'Chalatenango': ['Chalatenango', 'Agua Caliente', 'Arcatao', 'Azacualpa', 'Cancasque', 'Citalá', 'Comalapa', 'Concepción de Quezaltepeque', 'Dulce Nombre de María', 'El Carrizal', 'El Paraíso', 'La Laguna', 'La Palma', 'La Reina', 'Las Flores', 'Las Vueltas', 'Nombre de Jesús', 'Nueva Concepción', 'Nueva Trinidad', 'Ojos de Agua', 'Potonico', 'San Antonio de la Cruz', 'San Antonio los Ranchos', 'San Fernando', 'San Francisco Lempa', 'San Francisco Morazán', 'San Ignacio', 'San Isidro Labrador', 'San Luis del Carmen', 'San Miguel de Mercedes', 'San Rafael', 'Santa Rita', 'Tejutla'],
  'Cuscatlán': ['Cojutepeque', 'Candelaria', 'El Carmen', 'El Rosario', 'Monte San Juan', 'Oratorio de Concepción', 'San Bartolomé de Perulapía', 'San Cristóbal', 'San José Guayabal', 'San Pedro Perulapán', 'San Rafael Cedros', 'San Ramón', 'Santa Cruz Analquito', 'Santa Cruz Michapa', 'Suchitoto', 'Tenancingo'],
  'La Libertad': ['Santa Tecla', 'Antiguo Cuscatlán', 'Chiltiupán', 'Ciudad Arce', 'Colón', 'Comasagua', 'Huizúcar', 'Jayaque', 'Jicalpa', 'La Libertad', 'Nuevo Cuscatlán', 'Quezaltepeque', 'San Juan Opico', 'Sacacoyo', 'San José Villanueva', 'San Matías', 'San Pablo Tacachico', 'Talnique', 'Tamanique', 'Teotepeque', 'Tepecoyo', 'Zaragoza'],
  'La Paz': ['Zacatecoluca', 'Cuyultitán', 'El Rosario', 'Jerusalén', 'Mercedes La Ceiba', 'Olocuilta', 'Paraíso de Osorio', 'San Antonio Masahuat', 'San Emigdio', 'San Francisco Chinameca', 'San Pedro Masahuat', 'San Juan Nonualco', 'San Juan Talpa', 'San Juan Tepezontes', 'San Luis la Herradura', 'San Luis Talpa', 'San Miguel Tepezontes', 'San Pedro Nonualco', 'San Rafael Obrajuelo', 'Santa María Ostuma', 'Santiago Nonualco', 'Tapalhuaca'],
  'La Unión': ['La Unión', 'Anamorós', 'Bolívar', 'Concepción de Oriente', 'Conchagua', 'El Carmen', 'El Sauce', 'Intipucá', 'Lislique', 'Meanguera del Golfo', 'Nueva Esparta', 'Pasaquina', 'Polorós', 'San Alejo', 'San José', 'Santa Rosa de Lima', 'Yayantique', 'Yucuaiquín'],
  'Morazán': ['San Francisco Gotera', 'Arambala', 'Cacaopera', 'Chilanga', 'Corinto', 'Delicias de Concepción', 'El Divisadero', ' El Rosario', 'Gualococti', 'Guatajiagua', 'Joateca', 'Jocoaitique', 'Jocoro', 'Lolotiquillo', 'Meanguera', 'Osicala', 'Perquín', 'San Carlos', 'San Fernando', 'San Isidro', 'San Simón', 'Sensembra', 'Sociedad', 'Torola', 'Yamabal', 'Yoloiaquín'],
  'San Miguel': ['San Miguel', 'Carolina', 'Chapeltique', 'Chinameca', 'Chirilagua', 'Ciudad Barrios', 'Comocarán', 'El Tránsito', 'Lolotique', 'Moncagua', 'Nueva Guadalupe', 'Nuevo Eden de San Juan', 'Quelepa', 'San Antonio', 'San Gerardo', 'San Jorge', 'San Luis de la Reina', 'San Rafael de Oriente', 'Sesori', 'Uluazapa'],
  'San Salvador': ['San Salvador', 'Aguilares', 'Apopa', 'Ayutuxtepeque', 'Delgado', 'El Paisnal', 'Guazapa', 'Ilopango', 'Mexicanos', 'Nejapa', 'Panchimalco', 'Rosario de Mora', 'San Marcos', 'San Martín', 'Santiago Texacuangos', 'Santo Tomás', 'Soyapango', 'Tonacatepeque'],
  'San Vicente': ['San Vicente', 'Apastepeque', 'Guadalupe', 'San Cayetano Istepeque', 'San Esteban Catarina', 'San Idelfonso', 'San Lorenzo', 'San Sebastián', 'Santa Clara', 'Santo Domingo', 'Tecoluca', 'Tepetitán', 'Verapaz'],
  'Santa Ana': ['Santa Ana', 'Candelaria de la Frontera', 'Chalchuapa', 'Coatepeque', 'El Congo', 'El Porvenir', 'Masahuat', 'Metapán', 'San Antonio Pajonal', 'San Sebastian Salitrillo', 'Santa Rosa Guachipillín', 'Santiago de la Frontera', 'Texistepeque'],
  'Sonsonate': ['Sonsonate', 'Acajutla', 'Armenia', 'Caluco', 'Cuisnahuat', 'Izalco', 'Juayúa', 'Nahuizalco', 'Nahuilingo', 'Salcoatitán', 'San Antonio del Monte', 'San Julián', 'Santa Catarina Masahuat', 'Santa Isabel Ishuatán', 'Santo Domingo de Guzmán', 'Sonzacate'],
  'Usulután': ['Usulután', 'Alegría', 'Berlín', 'California', 'Concepción Batres', 'El Triungo', 'Ereguayquín', 'Estanzuelas', 'Jiquilisco', 'Jacuapa', 'Jucuarán', 'Mercedes Umaña', 'Nueva Granada', 'Ozatlán', 'Puerto el Triunfo', 'San Agustín', 'San Buenaventura', 'San Dionisio', 'San Francisco Javier', 'Santa Elena', 'Santa María', 'Santiago de María', 'Tecapán'],
};

export interface InnerObject {
  lat: number;
	lng: number;
}

export interface CenterObject {
	[key: string]: InnerObject;
}

export const COORDINATES: CenterObject = {
	'Parque Cuscatlan': {
		lat: 13.698927,
		lng: -89.206350,
	},
	'Parque Libertad': {
		lat: 13.994894,
		lng: -89.556493,
	},
	'Parque Guzman': {
		lat: 13.483127,
		lng: -88.175585,
	},
}

export const SERVICE_HISTORY_MOCK = [
  { id: 1, name: 'Grua pesada', date: '2020-10-26'},
  { id: 2, name: 'Cambio de llanta', date: '2020-10-23'}
]