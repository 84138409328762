import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CartContext } from '../../context/Cart.context';
import { formatter } from '../../lib/formatter';

import { SectionWrapper } from './styled';
import Form from '../../components/Form'
import BackArrow from '../../assets/icons/ic_atras.svg';
import AxiosInstance from '../../API/axiosInstance';
import { IProfileData } from '../Profile/types';

interface serviceObj {
	id?: string;
	sku?: string;
	title: string;
	price: number;
	extraKm?: number;
}

const Checkout: React.FC = () => {
	const [removing, setRemoving] = useState<boolean>(false);
	const [isCarService, setCarService] = useState<boolean>(false);
	const [distanceCharge, setDistanceCharge] = useState<number>(0);
	const [profile, setProfile] = useState<IProfileData | null>(null)
	const [isLogged, setIsLogged] = useState(true)
	const cartValue = useContext(CartContext);
	const { cartState, setCartState } = cartValue;
	let history = useHistory();
	cartState?.length < 1 && history.push('/confirmation');

	useEffect(() => {
		const found = cartState.find((obj: any) => obj.title.indexOf('Asistencia Vial') >= 0)
		if (found) {
			return setCarService(true);
		}
		return setCarService(false)
	}, [cartState])

	useEffect(() => {
		AxiosInstance.get('/me')
			.then(response => {
				setIsLogged(true)
				setProfile(response.data)
			})
			.catch(error => {
				console.error(error);
				setIsLogged(false)
			})
	}, [history])

	const removeFromCart = (service: serviceObj) => {
		setRemoving(true);
		setTimeout(() => {
			setRemoving(false)
		}, 700);
		if (cartState && setCartState) {
			const clone = [...cartState];
			const newCartState = clone.filter(element => element.title !== service.title);
			setCartState(newCartState)
			newCartState.length < 1 && history.push('/');
		};
	}

	const getTotal = () => cartState.reduce((acc: any, nxt: any) => acc + nxt.price + Number(distanceCharge), 0);
	const isAuth = !window.localStorage.getItem('Bearer');

	return (
		<>
			<SectionWrapper id="checkout-page">
				<div className="page-title">
					<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
					<h2>Confirma tu solicitud</h2>
					<span className="hide-on-med-and-down" />
				</div>

				<div className="container selected-items">
					<h5 className="main-label">Estos son los servicios que has seleccionado:</h5>

					<ul>
						{!removing ? cartState.map((obj: serviceObj, index: number) => (
							<li key={index}>
								{obj.title}
								<button onClick={() => removeFromCart(obj)} className="custom-button">
									Eliminar
									</button>
							</li>
						)) : (
								<div className="sk-chase">
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
								</div>
							)}
					</ul>
					{!removing && <p className="total">Total: <span className="total">{formatter.format(getTotal())}</span></p>}
				</div>

				<Form
					isAuth={isAuth}
					isCarService={isCarService}
					cartState={cartState}
					setDistanceCharge={setDistanceCharge}
					getTotal={getTotal}
					profile={profile}
					isLogged={isLogged}
				/>
			</SectionWrapper>
		</>
	)
};

export default Checkout;