import styled from 'styled-components';

export const Styled = styled.div`
	width: 80%;
  margin: 80px auto;

  @media (max-width: 768px) {
    width: 95%;
  }

  img {
    @media (max-width: 768px) {
      height: 75%;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    div {
      width: 50%;
      padding: 30px;

      @media (max-width: 768px) {
        width: 100%;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
        text-align: justify;
        width: 80%;
        font-weight: 500;
        margin-right: auto;
        margin-bottom: 20px;
        margin-left: auto;
    
        @media (max-width: 768px) {
          width: 95%;
          margin: 15px auto;
        }
      }
    }
  }
`;
