import React from 'react';
import { useHistory } from 'react-router-dom';

import { Styled } from './styled';
import BackArrow from '../../../assets/icons/ic_atras.svg';
import AxiosInstance from '../../../API/axiosInstance';

const EditProfile: React.FC = () => {
  let history = useHistory();

  const passwordState = {
    prev_password: '',
    password: '',
    confirmPassword: ''
  }

  interface ProfileData {
    firstName: string;
    lastName: string;
    email: string;
  }

  const profileState = {
    firstName: '',
    lastName: '',
    email: ''
  }

  const [passwordForm, setPasswordForm] = React.useState(passwordState)
  const [profileForm, setProfileForm] = React.useState<ProfileData>(profileState);

  const updatePasswordState = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setPasswordForm({
      ...passwordForm,
      [name]: value
    })
  }

  React.useEffect(() => {
    AxiosInstance.get('/me')
      .then(response => setProfileForm(response.data))
      .catch(error => {
        console.error(error)
      })
  }, [])

  const submitPassword = () => {
    if (passwordForm.password !== passwordForm.confirmPassword) {
      alert("Error!, las contraseñas no coinciden")
    } else {
      const data = {
        prev_password: passwordForm.prev_password,
        password: passwordForm.password
      }

      AxiosInstance.put('/update_password', {
        ...data
      })
        .then((response: any) => {
          if (response.status === 200) {
            alert("Contraseña cambiada correctamente")
            setPasswordForm(passwordState)
            history.push('/profile')
          } else if (response.status === 422) {
            alert("Error al cambiar contraseña, la contraseña debe llevar al menos una mayúscula, 1 numero y 1 caracter especial")
          }
        })
        .catch((error: any) => {
          const { response = {} } = error;
          const { data } = response;
          const { errors = [] } = data;
          if (errors && errors.length > 0) {
            alert(errors[0].detail)
          }
        })
    }
  }

  const submitProfile = () => {
    const data = {
      firstName: profileForm.firstName,
      lastName: profileForm.lastName,
      email: profileForm.email
    }
    console.log(data)
    AxiosInstance.put('/users', {
      ...data
    })
      .then((response: any) => {
        if (response.status === 200) {
          alert("Perfil actualizado correctamente")
          setProfileForm(profileState)
          history.push('/profile')
        }
      })
      .catch((error: any) => {
        const { response = {} } = error;
        const { data } = response;
        const { errors = [] } = data;
        if (errors && errors.length > 0) {
          alert(errors[0].detail)
        }
      })
  }

  return (
    <Styled id="edit-profile">
      <div className="page-title">
        <a className="hide-on-med-and-down" href="/profile"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
        <h2>Editar información personal</h2>
        <span className="hide-on-med-and-down" />
      </div>

      <h3>Información Personal</h3>

      <div className="row">
        <div className="col m6 s12">
          <label htmlFor="firstName">Nombres<span className="required-field">*</span></label>
          <input
            required
            type="text"
            id="firstName"
            name="Nombre"
            pattern="[A-Za-z ]*"
            className="validate"
            value={profileForm.firstName}
            onChange={({ target }) => {
              setProfileForm({
                ...profileForm,
                firstName: target.value
              })
            }}
          />
        </div>
        <div className="col m6 s12">
          <label htmlFor="lastName">Apellidos<span className="required-field">*</span></label>
          <input
            required
            type="text"
            id="lastName"
            name="Nombre"
            pattern="[A-Za-z ]*"
            className="validate"
            value={profileForm.lastName}
            onChange={({ target }) => {
              setProfileForm({
                ...profileForm,
                lastName: target.value
              })
            }}
          />
        </div>

        <div className="col m6 s12">
          <label htmlFor="email">Correo Electrónico<span className="required-field">*</span></label>
          <input
            required
            id="email"
            type="email"
            name="Correo"
            className="validate"
            value={profileForm.email}
            onChange={({ target }) => {
              setProfileForm({
                ...profileForm,
                email: target.value
              })
            }}
            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
          />
        </div>
      </div>
      <div className="row footer-action">
        <button className="custom-button enabled-button col m3 s8 offset-m2 offset-s2" onClick={submitProfile}>
          Guardar Perfil
          </button>

        <button onClick={() => history.push('/profile')} className="custom-button enabled-button col m3 s8 offset-m2 offset-s2">
          Cancelar
          </button>
      </div>

      <h3>Cambiar contraseña</h3>

      <div className="row">
        <div className="input-field col m6 s12">
          <input
            required
            type="password"
            id="prev_password"
            className="validate"
            name="prev_password"
            onChange={updatePasswordState}
          />
          <label htmlFor="firstName">Contraseña Actual<span className="required-field">*</span></label>
        </div>
        <div className="input-field col m6 s12">
          <input
            required
            id="password"
            type="password"
            className="validate"
            name="password"
            onChange={updatePasswordState}
          />
          <label htmlFor="email">Nueva Contraseña<span className="required-field">*</span></label>
        </div>
        <div className="input-field col m6 s12">
          <input
            required
            id="confirmPassword"
            type="password"
            className="validate"
            name="confirmPassword"
            onChange={updatePasswordState}
          />
          <label htmlFor="email">Confirmar Contraseña<span className="required-field">*</span></label>
        </div>
      </div>

      <div className="row footer-action">
        <button className="custom-button enabled-button col m3 s8 offset-m2 offset-s2" onClick={submitPassword}>
          Cambiar contraseña
          </button>

        <button onClick={() => history.push('/profile')} className="custom-button enabled-button col m3 s8 offset-m2 offset-s2">
          Cancelar
          </button>
      </div>
    </Styled>
  )
}

export default EditProfile;