import styled from 'styled-components';

export const Styled = styled.div`
  width: 100%;
  padding: 120px 10% 80px 10%;

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .add-service {
    display: block;
    height: 52px;
    width: 30%;
    text-align: center;
    padding: 16px 34px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    margin: 40px auto 0 auto;

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 576px) {
      width: 95%;
    }
  }
  `;
