import React from 'react';

import { Styled } from './styled';

const ConfirmationPage = () => (
  <Styled id="confirmation-page">
    <div className="page-title">
			<h2>Tu solicitud ha sido enviada con éxito</h2>
		</div>
    <p>Uno de nuestros agentes se comunicará contigo en unos minutos para gestionar el pago y asignación del servicio.</p>

    <a href="/" className="add-service custom-button">
      ir al inicio
    </a>
  </Styled>
);

export default ConfirmationPage;