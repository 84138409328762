import styled from 'styled-components';

export const Styled = styled.div`
  width: 80%;
  padding: 120px 0 80px 0;
  margin: 0 auto;

  h2 {
    font-size: 45px;
    text-align: center;
  }

  p {
    text-align: justify;
    margin-top; 40px;
  }

  ul {
    li {
      .article {
        font-weight: 700;
      }
    }
  }
`;