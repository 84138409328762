import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Styled } from './styled';
import BackArrow from '../../assets/icons/ic_atras.svg';
import AxiosInstance from '../../API/axiosInstance';

interface Address {
  id: string
  name: string | null,
  lat: string | number,
  lon: string | number,
  address: string
}

const Address = () => {
  const [addresses, setAddresses] = useState<Address[]>([])
  useEffect(() => {
    AxiosInstance.get('/addresses').then((response) => setAddresses(response.data))
  }, [])

  return (
    <Styled>
      <div className="page-title">
        <a className="hide-on-med-and-down" href="/profile"><img src={BackArrow} alt="Regresar a la página principal" /></a>
        <h2>Tus direcciones</h2>
        <span className="hide-on-med-and-down" />
      </div>

      <Link to="/address/edit/new" className="custom-button">
        Agregar dirección
    </Link>

      { addresses.map((item: Address) => (
        <section key={item.id} className="address-list">
          <Link to={`/address/edit/${item.id}`}>
            {item.name}
          </Link>
        </section>
      ))}
    </Styled>
  )
};

export default Address;