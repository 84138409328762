import styled from 'styled-components';

export const Styled = styled.div`
  width: 100%;
	padding: 20px 40px 80px 40px;
	margin: 80px 0;
	
	label {
		font-size: 16px;
	}

	.map {
		margin-top: 40px;
	}

  .custom-button {
		display: block;
		font-size: 21px;
		font-weight: 700;
		padding: 16px;
		border-radius: 10px;
		border-width: 2px;
		margin: 80px auto 40px auto;

		@media (max-width: 576px) {
			width: 80%;
		}
	}

	.footer-action {
		margin-top: 40px;
		
		.custom-button {
			margin-top: 20px;
		}
	}
`;