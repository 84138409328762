import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, Autocomplete, AutocompleteProps } from '@react-google-maps/api';

export interface ILocation {
	lat: number;
	lng: number;
}

interface Props {
	location: ILocation
	destination?: (coordinates: ILocation) => void;
}

interface AutocompleteNewProps extends AutocompleteProps {
	getPlace: Function;
}

const containerStyle = {
	width: '100%',
	height: '400px'
};

const Map = ({ location, destination }: Props) => {
	const [autocomplete, setAutocomplete] = useState<AutocompleteNewProps>();
	const position = {
		lat: location.lat,
		lng: location.lng
	}

	const options = {
		fullscreenControl: true,
	}

	useEffect(() => {
	}, [autocomplete])

	const onLoad = (startAutocomplete: any) => {
		setAutocomplete(startAutocomplete)
	}

	const onPlaceChanged = () => {
		if (autocomplete && autocomplete.getPlace().geometry) {
			destination && destination({
				lat: autocomplete.getPlace().geometry.location.lat(),
				lng: autocomplete.getPlace().geometry.location.lng()
			})
		} else {
			console.log('Autocomplete is not loaded yet!')
		}
	}

	return (
		<GoogleMap
			onClick={(event) => {
				destination && destination({ lat: event.latLng.lat(), lng: event.latLng.lng() })
			}}
			mapContainerStyle={containerStyle}
			center={position}
			zoom={15}
			options={options}
		>
			<Marker
				position={position}
			/>
			<Autocomplete
				onLoad={onLoad}
				onPlaceChanged={onPlaceChanged}
			>
				<input
					type="text"
					placeholder="Ingresa la dirección"
					style={{
						boxSizing: `border-box`,
						border: `1px solid transparent`,
						width: `260px`,
						height: `60px`,
						padding: `0 12px`,
						borderRadius: `3px`,
						boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
						fontSize: `14px`,
						outline: `none`,
						textOverflow: `ellipses`,
						position: "absolute",
						left: "50%",
						backgroundColor: "#fcfcfc",
						marginTop: window.innerWidth < 750 ? "55px" : "0",
						marginLeft: "-120px"
					}}
				/>
			</Autocomplete>
		</GoogleMap>
	)
}

export default Map;
