import React, { useContext, useEffect, useState } from 'react';

import AxiosInstance from '../../API/axiosInstance';
import { formatter } from '../../lib/formatter';

import Styled from './styled';
import BackArrow from '../../assets/icons/ic_atras.svg';
import { CartContext } from '../../context/Cart.context';
import Grua from '../../assets/icons/ic_grua.svg';
import Rayo from '../../assets/icons/ic_electricidad_new.svg';
import Llanta from '../../assets/icons/ic_llantas.svg';
import Gas from '../../assets/icons/ic_combustible.svg';
import Llave from '../../assets/icons/ic_cerrajeria.svg';

export interface ParentService {
    id: string;
    name: string;
    created_at: Date;
    updated_at: Date;
}
export interface ServiceType {
    id: string;
    sku: string;
    title: string;
    price: number;
    extraKm: number;
    parentService: ParentService;
}

export interface SubServices {
  title: string;
  serviceTypes: ServiceType[];
}

export interface TypeServiceObject {
  name: string;
  subServices: SubServices[];
}

const ServiceInternal = () => {
  const [error, setError] = useState(false);
  const [serviceObj, setServiceObj] = useState<TypeServiceObject>()
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const CURRENT_SERVICE = window.location.pathname.split('/').pop()?.replace('-', ' ').toLowerCase();
  const cartValue = useContext(CartContext);

  useEffect (() => {
    AxiosInstance.get('/services/service_types')
    .then(response => {
      const service = response.data.find((_: any) => _.name.toLowerCase() === CURRENT_SERVICE);
      setPageLoading(false)
      setServiceObj(service);
      })
      .catch(error => {
        console.error(error)
        setError(true);
        setTimeout(() => setError(false), 3000)
      })
  }, [CURRENT_SERVICE])

  const getImage = (title: string) => {
    switch (title) {
      case 'Grua pesada':
      case 'Grua para motocicleta':
      case 'Grua Liviana':
        return <img src={Grua} alt="servicio de grua" />
      case 'Cambio de Llanta':
      case 'Envío de reparación de Llanta':
        return <img src={Llanta} alt="Servicio de llanta" />
      case 'Paso de Corriente':
        return <img src={Rayo} alt="paso de electricidad" />
      case 'Apertura de Cerrajería Vehicular':
        return <img src={Llave} alt="cerrajería vehicular" />
      case 'Abastecimiento de Combustible':
        return <img src={Gas} alt="abastecimiento de combustible" />
      default:
        return
    }
  }

  const addToCart = (service: ServiceType) => {
    const { cartState, setCartState } = cartValue;
    if (cartState && setCartState) {
      const clone = cartState.slice();
      setCartState([...clone, { ...service }])
		};
		setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  }
  
  return (
    <Styled id="service-internal">
      { error && <span className="error-msg">¡Lo sentimos! Hubo un error obteniendo la información</span> }
      <div className="page-title">
        <a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
        <h2>{CURRENT_SERVICE}</h2>
        <span className="hide-on-med-and-down" />
      </div>

      <section className="services-list">
        { pageLoading && (
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        ) }
        { serviceObj?.subServices.map((subService: SubServices, index: number) => (
          <details key={index}>
            <summary>
              { getImage(subService.title) }
              {subService.title}
            </summary>
            { subService.serviceTypes.map((serviceType: ServiceType) => (
              <div key={serviceType.id}>
                { loading ? (
                  <div className="sk-chase">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                  </div>
                ) : (
                  <>
                    <span>{serviceType.title}</span>
                    <span>{formatter.format(serviceType.price)}</span>
                    <button className="custom-button" onClick={() => addToCart(serviceType)}>Agregar a carrito</button>
                  </>
                ) }
              </div>
            )) }
          </details>
        )) }
      </section>
    </Styled>
  )
};

export default ServiceInternal;
