import styled from 'styled-components';

export default styled.div`
  width: 80%;
  padding: 40px 0;
  margin: 0 auto;

  div {
    h2 {
      text-transform: capitalize;
    }
  }

  .services-list {
    details {
      summary {
        font-size: 24px;
        font-weight: 700;
        margin-top: 40px;
  
        &::marker {
          font-size: 12px;
        }
  
        img {
          height: 30px;
          margin-right: 16px;
          margin-left: 8px;
        }
      }

      div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 28px;

        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          margin-right: 16px;
        }

        .custom-button {
          display: block;
          font-size: 16px;
          font-weight: 700;
          padding: 12px 24px;
          border-radius: 4px;
          border-width: 1px;
          transition: .3s;
          margin-left: auto;
        }
      }
    }
  }
`;