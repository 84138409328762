import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import AxiosInstance from '../../API/axiosInstance';
import Mail from '../../assets/icons/ic_mail.svg';
import User from '../../assets/icons/ic_perfil.svg';
import Lock from '../../assets/icons/ic_pass.svg';
import { Styled } from './styled';

const Authentication: React.FC = () => {
	const [keyframe, setKeyframe] = useState('login');
	const [error, setError] = useState(false);
	const [login, setLogin] = useState({ email: '', password: '', "grant_type": "password" });
	const [signup, setSignup] = useState({ first_name: '', last_name: '', email: '', password: '' })

	let history = useHistory();
	let { path } = useRouteMatch()

	useEffect(() => {
		if (path.indexOf('?#') < 0) {
			window.location.href = "login?#"
		}
	}, [path])

	const saveToken = (access_token: string) => {
		localStorage.setItem('Bearer', access_token);
	}

	const attemptLogin = () => {
		AxiosInstance.post('/oauth/token', {
			...login
		})
			.then((response) => {
				if (response.status === 200) {
					Promise.all([saveToken(response.data.access_token)]).then(() => {
						window.location.href = '/?success=true';
					})
				}
			})
			.catch(({ errors }) => {
				const MSG = errors?.detail || '¡Lo sentimos! Hubo un problema procesando tu petición.'
				console.error(MSG)
				setError(MSG);
				setTimeout(() => setError(false), 5000)
			})
	};

	const attemptSignup = () => {
		AxiosInstance.post('/users/signup', {
			...signup
		})
			.then(response => {
				window.localStorage.setItem('Bearer', response.data.access_token);
				history.push('/?success=true');
			})
			.catch(({ errors }) => {
				const MSG = errors?.detail || '¡Lo sentimos! Hubo un problema procesando tu petición.'
				console.error(MSG)
				setError(MSG);
				setTimeout(() => setError(false), 5000)
			})
	};

	return (
		<Styled className="access-wrapper">
			{ error && <span className="error-msg">{error}</span>}
			<div id="container">
				{ keyframe === 'login' ? (
					<div className="wrapper">
						<form action="#">
							<h1>Iniciar Sesión</h1>
							<div className="input-with-icon">
								<img src={User} alt="silueta de perfil" />
								<input
									value={login.email}
									type="email"
									placeholder="Correo electrónico"
									onChange={({ target }) => {
										const _ = { ...login, email: target.value }
										setLogin(_)
									}}
								/>
							</div>
							<div className="input-with-icon">
								<img src={Lock} alt="candado" />
								<input
									value={login.password}
									type="password"
									placeholder="Contraseña"
									onChange={({ target }) => {
										const _ = { ...login, password: target.value }
										setLogin(_)
									}}
								/>
							</div>
							<a className="forgot-pass" href="/forgot-pass">Recuperar mi contraseña</a>
							<button onClick={attemptLogin} className="custom-button">Iniciar Sesión</button>
						</form>
						<div className="info-panel">
							<h1>¿Aún no tienes cuenta?</h1>
							<p>Regístrate para tener más beneficios y control de los servicios que solicites.</p>
							<button onClick={() => setKeyframe('signup')} className="custom-button" id="signUp">Crear Cuenta</button>
						</div>
					</div>
				) : (
					<div className="wrapper">
						<div className="info-panel">
							<h1>Iniciar Sesión</h1>
							<p>Ingresa con tu correo electrónico y consulta información de tu cuenta y los servicios que has solicitado.</p>
							<button onClick={() => setKeyframe('login')} className="custom-button" id="signIn">Iniciar Sesión</button>
						</div>
						<form action="#">
							<h1>¿Aún no tienes cuenta?</h1>
							<div className="input-with-icon">
								<img src={User} alt="silueta de perfil" />
								<input
									value={signup.first_name}
									type="text"
									placeholder="Nombre"
									onChange={({ target }) => {
										const _ = { ...signup, first_name: target.value }
										setSignup(_)
									}}
								/>
							</div>
							<div className="input-with-icon">
								<img src={User} alt="silueta de perfil" />
								<input
									value={signup.last_name}
									type="text"
									placeholder="Apellido"
									onChange={({ target }) => {
										const _ = { ...signup, last_name: target.value }
										setSignup(_)
									}}
								/>
							</div>
							<div className="input-with-icon">
								<img src={Mail} alt="sobre de correo" />
								<input
									value={signup.email}
									type="email"
									placeholder="Correo electrónico"
									onChange={({ target }) => {
										const _ = { ...signup, email: target.value }
										setSignup(_)
									}}
								/>
							</div>
							<div className="input-with-icon">
								<img src={Lock} alt="candado" />
								<input
									value={signup.password}
									type="password"
									placeholder="Contraseña"
									onChange={({ target }) => {
										const _ = { ...signup, password: target.value }
										setSignup(_)
									}}
								/>
							</div>
							<label>Tu contraseña debe de tener al menos 8 carácteres, minúsculas, mayúsculas, al menos un número y al menos un caracter especial.</label>
							<button onClick={attemptSignup} className="custom-button">Crear Cuenta</button>
						</form>
					</div>
				) }
			</div>
		</Styled>
	)
};

export default Authentication;