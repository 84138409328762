import React, { useState, createContext } from 'react';

interface EventTarget {
  target: string;
}

interface Props {
  menuState: EventTarget;
  setMenuState: Function;
};

export const MenuContext = createContext<Partial<Props>>({});

export const MenuProvider: React.FC = ({ children }) => {
  const [menuState, setMenuState] = useState({
    target: '',
  })
  const value = { menuState, setMenuState }
  return (
    <MenuContext.Provider value={value}>
      { children }
    </MenuContext.Provider>
  )
}
