import React from 'react';
import { Link } from 'react-router-dom';

import { Styled } from './styled';

import FacebookWhite from '../../assets/icons/facebook-white.svg';
import InstagramWhite from '../../assets/icons/instagram-white.svg';
import WhatsappWhite from '../../assets/icons/whatsapp-white.svg';

const date = new Date();
const year = date.getFullYear();

const Footer: React.FC = () => (
  <Styled>
    <div id="copyright">
      <p>INTEGRA SOLUTIONS S.A de C.V {year} &#174;</p>
    </div>

    <div className="links-wrapper">
      <div>
				<button className="styled-link">
          <Link to='privacidad'>Política de Privacidad</Link>
        </button>
        <button className="styled-link">
          <Link to='condiciones'>Términos y Condiciones</Link>
        </button>
				<button className="styled-link">
					<a href="mailto:soporte@quickfixca.com" target="_blank" rel="noopener noreferrer">
						Soporte Quickfix
					</a>
				</button>
      </div>
    </div>

    <span className="social-icons">
      <a href="https://www.facebook.com/Quik-Fix-101904401665160/" target="_blank" rel="noopener noreferrer"><img src={FacebookWhite} alt="Link a nuestro Facebook" title="Facebook" /></a>
      <a href="https://instagram.com/quickfixca?igshid=cs0hisulut8p " target="_blank" rel="noopener noreferrer"><img src={InstagramWhite} alt="Link a nuestro Instagram" title="Instagram" /></a>
      <a href="https://twitter.com/QuickFixca?s=09" target="_blank" rel="noopener noreferrer"><img src={WhatsappWhite} alt="Link a nuestro chat de WhatsApp" title="Whatsapp" /></a>
    </span>
  </Styled>
);

export default Footer;