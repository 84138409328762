import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Styled } from './styled';

import Partners from '../../assets/images/socios.png';
import One from '../../assets/icons/ic_1_white.svg';
import Two from '../../assets/icons/ic_2_white.svg';
import Three from '../../assets/icons/ic_3_white.svg';
import Four from '../../assets/icons/ic_check_white.svg';
import ScrollIndicator from '../../assets/icons/ic_scroll.svg';
import CarAssistanceGray from '../../assets/icons/gray-icons/asistencia_vial_gris.svg';
import DesktopCarAssistance from '../../assets/images/Desktop_asistencia_vial.png';
import MobileCarAssistance from '../../assets/images/Mobile_Asistencia_vial.png';
import GrayDots from '../../assets/icons/Fondo_puntos.svg';
import ElectricityGray from '../../assets/icons/gray-icons/electricidad_gris.svg';
import HomeGray from '../../assets/icons/gray-icons/hogar_gris.svg';
import DesktopElectricity from '../../assets/images/Desktop_Reparaciones_electricas.png';
import DesktopHome from '../../assets/images/Desktop_Hogar.png';
import MobileElectricity from '../../assets/images/Mobile_Reparaciones_electricas.png';
import Key from '../../assets/icons/ic_cerrajeria_new.svg';
import Volt from '../../assets/icons/ic_electricidad_new.svg';
import Gear from '../../assets/icons/ic_reparaciones_new.svg';
import Cone from '../../assets/icons/ic_transito_new.svg';

const Homepage: React.FC = () => {
	const [welcome, setWelcome] = useState<Boolean | string>();

	useEffect(() => {
		setWelcome(String(window.location).split('?')[1])
		setTimeout(() => setWelcome(false), 3000)
	}, [])

	return (
		<Styled>
			{ welcome && <span className="error-msg success-msg">Bienvenido</span> }
			<div className="above-the-fold">
				{/* ICONS FOR DESKTOP */}
				<img className="hide-on-med-and-down service-floating-icon top-left" src={Key} alt="llave" />
				<img className="hide-on-med-and-down service-floating-icon top-right" src={Volt} alt="rayo eléctrico" />
				<img className="hide-on-med-and-down service-floating-icon bottom-left" src={Cone} alt="cono de tránsito" />
				<img className="hide-on-med-and-down service-floating-icon bottom-right" src={Gear} alt="engranaje" />
				{/* GRID IMAGES */}
				<img id="left" className="dots-grid hide-on-small-only" src={GrayDots} alt="puntos gris" />
				<img id="right" className="dots-grid hide-on-small-only" src={GrayDots} alt="puntos gris" />
				{/* GRID FOR MOBILE */}
				<img id="center" className="dots-grid hide-on-med-and-up" src={GrayDots} alt="puntos gris" />
				<div id="banner-info">
					<section>
						<h1>¿Necesitas ayuda inmediata?</h1>
						<p>Te conectamos con expertos y personal calificado para facilitarte auxilio y asistencia vial, servicios variados de fontanería, electricidad, aire acondicionado y limpieza del hogar todo al alcance de un click. </p>
					</section>
				</div>
			</div>
			<section className="how-it-works">
				<div id="service-steps">
					<div className="service-row">
						<img src={One} alt="primer paso" />
						<p>Selecciona un servicio.</p>
					</div>

					<div className="service-row">
						<img src={Two} alt="segundo paso" />
						<p>Completa la solicitud.</p>
					</div>

					<div className="service-row">
						<img src={Three} alt="tercer paso" />
						<p>Realiza tu pago.</p>
					</div>

					<div className="service-row">
						<img src={Four} alt="cuarto paso" />
						<p>¡Listo!</p>
					</div>
				</div>
				<img id="scroll-indicator" src={ScrollIndicator} alt="scroll" />
				<h2>Nuestros servicios</h2>
				<p>Encuentra una gran variedad de opciones en nuestros catálogos de servicios, consulta nuestras categorías.</p>
			</section>
			<section className="our-services">

				<div className="services-grid">
					<section>
						<div className="image-background electricity hide-on-small-only" />
						<div className="service-detail car-assistance-details">
							<img className="detail-image hide-on-small-only" src={DesktopCarAssistance} alt="Asistencia vial" />
							<img className="detail-image hide-on-large-only" src={MobileCarAssistance} alt="Asistencia vial" />
							<>
								<img src={CarAssistanceGray} alt="asistencia vial" />
								<h3>Asistencia Vial</h3>
								<p>Te ofrecemos diferentes servicios de reparaciones en tu casa u oficina.</p>
								<Link to="/servicio/asistencia-vial">Ver catálogo</Link>
							</>
						</div>
					</section>

					<section>
						<div className="service-detail electricity-details">
							<img className="detail-image hide-on-small-only" src={DesktopElectricity} alt="Asistencia eléctrica" />
							<img className="detail-image hide-on-large-only" src={MobileElectricity} alt="Asistencia eléctrica" />
							<>
								<img src={ElectricityGray} alt="reparaciones eléctricas" />
								<h3>Reparaciones eléctricas</h3>
								<p>Te ofrecemos diferentes servicios de reparaciones en tu casa u oficina.</p>
								<Link to="/servicio/electricidad">Ver catálogo</Link>
							</>
						</div>
						<div className="image-background car-assistance hide-on-small-only" />
					</section>

					<section>
						<div className="image-background key hide-on-small-only" />
						<div className="service-detail home-details">
							<img className="detail-image" src={DesktopHome} alt="Asistencia en el hogar" />
							<>
								<img src={HomeGray} alt="reparaciones en tu hogar" />
								<h3>Fontanería</h3>
								<p>Te ofrecemos diferentes servicios de reparaciones en tu casa u oficina.</p>
								<Link to="/servicio/fontaneria">Ver catálogo</Link>
							</>
						</div>
					</section>
				</div>
			</section>

			<section className="join-us">
				<img src={Partners} alt="nuestros socios" />
				<div>
					<h2>Únete a nuestro grupo de contratistas</h2>
					<p>Ofrece tus servicios en nuestra plataforma y forma parte de nuestra red de expertos, potencia el alcanse de oferta de tus servicios y las áreas de cobertura de tu empresa, en QuickFix nos preocupamos por ofrecer procesos digitales estandarizados que se adapten a tus procesos de trabajo, para garantizar el mejor servicio para nuestros clientes.</p>
					<Link to="/unete">Quiero ser socio</Link>
				</div>
			</section>
		</Styled>
	);
};

export default Homepage;