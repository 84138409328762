import React from 'react';

import { Styled } from './styled';
import { SERVICE_HISTORY_MOCK } from '../../lib/constants';
import BackArrow from '../../assets/icons/ic_atras.svg';

const History:React.FC = () => (
  <Styled>
    
    <div className="page-title">
			<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
			<h2>Historial de servicio</h2>
			<span className="hide-on-med-and-down" />
		</div>

    <section>
      {SERVICE_HISTORY_MOCK.map(service => (
        <div key={service.id} className="service-row">
          <p>Servicio: {service.name}</p>
          <p>Fecha: {service.date}</p>
        </div>
      ))}
    </section>
  </Styled>
);

export default History;