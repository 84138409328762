/* export const getDistance = (lat1: number, lat2: number, lon1: number, lon2: number ): number => {
	const R = 6371e3;
	const φ1 = lat1 * Math.PI/180;
	const φ2 = lat2 * Math.PI/180;
	const Δφ = (lat2-lat1) * Math.PI/180;
	const Δλ = (lon2-lon1) * Math.PI/180;

	const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
						Math.cos(φ1) * Math.cos(φ2) *
						Math.sin(Δλ/2) * Math.sin(Δλ/2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	const d = R * c;
	return d;
} */

export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit === "K") { dist = dist * 1.609344 }
		if (unit === "N") { dist = dist * 0.8684 }
		const fixedDist = dist > 10 ? 0.8 * dist + dist : 0.65 * dist + dist;
		return fixedDist;
	}
}

/* const calculateDistance = (lat: number, lon: number): object => {
	let shortestCenter: object = {};
	let shortest: number;
	Object.keys(COORDINATES).forEach((center): void => {
		const current = getDistance(lat, lon, COORDINATES[center].lat, COORDINATES[center].lon, 'K');
		if (shortest > current || !shortest) {
			shortest = current;
			shortestCenter = {
				name: center,
				distance: shortest
			}
		}
	});

	return shortestCenter;
} */