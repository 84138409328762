import styled from 'styled-components';

export const Styled = styled.div`
	width: 80%;
	padding-top: 20px;
	margin: 40px auto 0 auto;

	@media (max-width: 675px) {
		width: 90%;
	}

	h2 {
		text-align: center;
		line-height: 1.4;
	}

	h3 {
		font-size: 24px;
		font-weight: 700;
		color: black;
		line-height: 1.4;
		margin-bottom: 20px;
	}

	p {
		font-size: 16px;
		color: black;
		line-height: 1.6;
	}

	.covid-section {
		position: relative;
		left: calc(-50vw + 50%);
		width: 100vw;
		padding: 80px 0;
		margin-top: 90px;

		div {
			width: 80%;
			margin: 0 auto;

			h3, p, ul > li {
				line-height: 1.7;
			}

			h3 {
				font-weight: 800;
			}
		}
	}
`;