import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import { useHistory } from 'react-router-dom';

import { Styled } from './styles';
import BackArrow from '../../assets/icons/ic_atras.svg';
import AxiosInstance from '../../API/axiosInstance';

interface IJoinUs {
	name: string,
	email: string,
	nit: string,
	nrc: string,
	phone: string,
	cell: string,
	address: string,
	type_of_taxpayer: string,
	giro: string,
	legal_representative_name: string,
	legal_representative_nit: string,
	legal_representative_phone: string,
	legal_representative_email: string,
	legal_representative_has_shares: string,
	legal_representative_has_employees: string,
	has_occupational_security: string,
	number_of_employees: string,
	plant_employees: string,
	eventual_employees: string,
	coverage_areas: string,
	restrictions: string,
	number_of_vehicles: string,
	services: string[]
}

const JoinUs = () => {

	let history = useHistory();

	const initialState = {
		name: '',
		email: '',
		nit: '',
		nrc: '',
		phone: '',
		cell: '',
		address: '',
		type_of_taxpayer: '',
		giro: '',
		legal_representative_name: '',
		legal_representative_nit: '',
		legal_representative_phone: '',
		legal_representative_email: '',
		legal_representative_has_shares: '',
		legal_representative_has_employees: '',
		has_occupational_security: '',
		number_of_employees: '',
		plant_employees: '',
		eventual_employees: '',
		coverage_areas: '',
		restrictions: '',
		number_of_vehicles: '',
		services: []
	}

	const [form, setForm] = useState<IJoinUs>(initialState)
	useEffect(() => {
		const select = document.querySelectorAll('select');
		M.FormSelect.init(select);
	});

	const updateInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value })
	}

	const updateCheckbox = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		if (form.services.includes(value)) {
			const newServices = form.services.filter(s => s !== value)
			setForm({ ...form, services: newServices })
		} else {
			form.services.push(value)
			setForm({ ...form, services: form.services })
		}
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()
		const data = {
			...form,
			services: form.services.join()
		}

		AxiosInstance.post('/partners', {
			...data
		})
			.then(() => history.push('/'))
			.catch(error => {
				console.error(error);
				if (error.response.status === 401 || error.response.status === 403) history.push('/login')
			});
	}

	const isChecked = (service: string) => {
		return form.services.includes(service)
	}

	return (
		<Styled id="join-us">
			<div className="page-title">
				<a className="hide-on-med-and-down" href="/"><img src={BackArrow} alt="regresar a la pagina princila" /></a>
				<h2>Formulario para unirte</h2>
				<span className="hide-on-med-and-down" />
			</div>

			<form
				id="partnersForm"
				method="post"
				className="pageclip-form col s12"
				onSubmit={handleSubmit}
			>

				<h5 className="main-label">Información de la empresa</h5>
				<div className="row">
					<div className="input-field col s12 m6">
						<input
							required
							type="text"
							id="name"
							pattern="[A-Za-z ]*"
							className="validate"
							name="name"
							onChange={updateInput}
						/>
						<label htmlFor="name">Nombre<span className="required-field">*</span></label>
					</div>
					<div className="input-field col s12 m6">
						<input
							required
							id="email"
							type="email"
							name="email"
							onChange={updateInput}
							className="validate"
							pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
						/>
						<label htmlFor="email">Correo Electrónico</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field col s12 m3">
						<input
							required
							type="text"
							id="nit"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							className="validate"
							name="nit"
							onChange={updateInput}
						/>
						<label htmlFor="nit">NIT</label>
					</div>
					<div className="input-field col s12 m3">
						<input
							required
							type="text"
							id="nrc"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							className="validate"
							name="nrc"
							onChange={updateInput}
						/>
						<label htmlFor="nrc">NRC</label>
					</div>
					<div className="input-field col s12 m3">
						<input
							required
							id="phone"
							type="text"
							className="validate"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							name="phone"
							onChange={updateInput}
						/>
						<label htmlFor="phone">Teléfono fijo</label>
					</div>
					<div className="input-field col s12 m3">
						<input
							required
							id="cell"
							type="text"
							className="validate"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							name="cell"
							onChange={updateInput}
						/>
						<label htmlFor="cell">Celular</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field col s12">
						<input
							type="text"
							id="address"
							name="address"
							onChange={updateInput}
							className="validate" />
						<label htmlFor="address">Dirección</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field col s12 m6">
						<input
							required
							type="text"
							id="type_of_taxpayer"
							pattern="[A-Za-z ]*"
							className="validate"
							name="type_of_taxpayer"
							onChange={updateInput}
						/>
						<label htmlFor="type_of_taxpayer">Tipo de Contribuyente</label>
					</div>
					<div className="input-field col s12 m6">
						<input
							required
							type="text"
							id="giro"
							pattern="[A-Za-z ]*"
							className="validate"
							name="giro"
							onChange={updateInput}
						/>
						<label htmlFor="giro">Giro</label>
					</div>
				</div>

				<h5 className="main-label">Servicios que ofrece</h5>
				<p>Seleccione todos los que apliquen</p>

				<div className="row">
					<div className="input-field col m6 s12">
						<p>
							<label>
								<input
									name="services"
									value="fontaneria"
									onChange={updateCheckbox}
									checked={isChecked("fontaneria") ? true : false}
									type="checkbox" />
								<span>Fontanería</span>
							</label>
						</p>
						<p>
							<label>
								<input
									name="services"
									value="carpinteria"
									onChange={updateCheckbox}
									checked={isChecked("carpinteria") ? true : false}
									type="checkbox" />
								<span>Carpintería</span>
							</label>
						</p>
						<p>
							<label>
								<input
									name="services"
									value="mantenimiento_electronico"
									onChange={updateCheckbox}
									checked={isChecked("mantenimiento_electronico") ? true : false}
									type="checkbox" />
								<span>Mantenimiento Equipos Electrónicos</span>
							</label>
						</p>
						<p>
							<label>
								<input
									name="services"
									value="mantenimiento"
									onChange={updateCheckbox}
									checked={isChecked("mantenimiento") ? true : false}
									type="checkbox" />
								<span>Mantenimiento</span>
							</label>
						</p>
					</div>
					<div className="input-field col m6 s12">
						<p>
							<label>
								<input
									name="services"
									value="construccion"
									onChange={updateCheckbox}
									checked={isChecked("construccion") ? true : false}
									type="checkbox" />
								<span>Construcctión</span>
							</label>
						</p>
						<p>
							<label>
								<input
									name="services"
									value="servicios_limpieza"
									onChange={updateCheckbox}
									checked={isChecked("servicios_limpieza") ? true : false}
									type="checkbox" />
								<span>Servicios de limpieza (Doméstica o Empresarial)</span>
							</label>
						</p>
						<p>
							<label>
								<input
									name="services"
									value="servicios_grua"
									onChange={updateCheckbox}
									checked={isChecked("servicios_grua") ? true : false}
									type="checkbox" />
								<span>Servicios de grua</span>
							</label>
						</p>
					</div>
				</div>

				<h5 className="main-label">Datos del Representante Legal</h5>

				<div className="row">
					<div className="input-field col s12">
						<input
							required
							type="text"
							id="legal_representative_name"
							pattern="[A-Za-z ]*"
							className="validate"
							name="legal_representative_name"
							onChange={updateInput}
						/>
						<label htmlFor="legal_representative_name">Nombre<span className="required-field">*</span></label>
					</div>
				</div>

				<div className="row">
					<div className="input-field col s12 m4">
						<input
							required
							type="text"
							id="legal_representative_nit"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							className="validate"
							name="legal_representative_nit"
							onChange={updateInput}
						/>
						<label htmlFor="legal_representative_nit">NIT</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							required
							type="text"
							id="legal_representative_phone"
							pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							className="validate"
							name="legal_representative_phone"
							onChange={updateInput}
						/>
						<label htmlFor="legal_representative_phone">Número telefónico</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							required
							type="text"
							id="legal_representative_email"
							pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
							className="validate"
							name="legal_representative_email"
							onChange={updateInput}
						/>
						<label htmlFor="legal_representative_email">Correo electrónico</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field">
						<select defaultValue="" required name="legal_representative_has_shares" onChange={updateInput}>
							<option value="">Selecciona una opción</option>
							<option value="true">Sí</option>
							<option value="false">No</option>
						</select>
						<label>¿Posee acciones de la empresa?<span className="required-field">*</span></label>
					</div>
				</div>

				<div className="row">
					<div className="input-field">
						<select defaultValue="" required name="legal_representative_has_employees" onChange={updateInput}>
							<option value="">Selecciona una opción</option>
							<option value="true">Sí</option>
							<option value="false">No</option>
						</select>
						<label>¿Posee empleados a su cargo?<span className="required-field">*</span></label>
					</div>
				</div>

				<h5 className="main-label">Información del personal</h5>

				<div className="row">
					<div className="input-field col s12 m4">
						<input
							required
							id="number_of_employees"
							type="text"
							className="validate"
							pattern="^[0-9]*$"
							name="number_of_employees"
							onChange={updateInput}
						/>
						<label htmlFor="number_of_employees">Número de empleados</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							required
							id="plant_employees"
							type="text"
							className="validate"
							pattern="^[0-9]*$"
							name="plant_employees"
							onChange={updateInput}
						/>
						<label htmlFor="plant_employees">Empleados cotizantes</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							required
							id="eventual_employees"
							type="text"
							className="validate"
							pattern="^[0-9]*$"
							name="eventual_employees"
							onChange={updateInput}
						/>
						<label htmlFor="eventual_employees">Empleados eventuales</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field">
						<select defaultValue="" required name="has_occupational_security" onChange={updateInput}>
							<option value="">Selecciona una opción</option>
							<option value="true">Sí</option>
							<option value="false">No</option>
						</select>
						<label>¿Posee el equipo de seguridad ocupacional?<span className="required-field">*</span></label>
					</div>
				</div>

				<h5 className="main-label">Información operativa</h5>

				<div className="row">
					<div className="input-field col s12 m6">
						<input
							required
							type="text"
							id="coverage_areas"
							pattern="[A-Za-z ]*"
							className="validate"
							name="coverage_areas"
							onChange={updateInput}
						/>
						<label htmlFor="coverage_areas">Zonas de cobertura</label>
					</div>
					<div className="input-field col s12 m6">
						<input
							required
							type="text"
							id="restrictions"
							pattern="[A-Za-z ]*"
							className="validate"
							name="restrictions"
							onChange={updateInput}
						/>
						<label htmlFor="restrictions">Restricciones</label>
					</div>
				</div>

				<div className="row">
					<div className="input-field col s12 m4">
						<input
							required
							id="number_of_vehicles"
							type="text"
							className="validate"
							pattern="^[0-9]*$"
							name="number_of_vehicles"
							onChange={updateInput}
						/>
						<label htmlFor="number_of_vehicles">Número de vehículos</label>
					</div>
				</div>

				<button
					type="submit"
					name="action"
					className="custom-button"
				>
					Enviar
				</button>
			</form>
		</Styled>
	);
};

export default JoinUs;