import React, { useState, useContext, useEffect } from 'react';
import M from 'materialize-css';
import { useHistory } from 'react-router-dom';

import { FormContext } from '../../context/Form.context';
import { CARS } from '../../lib/constants';
import { Styled } from './styled';
import Map from '../Map';
import { getDistance } from '../../lib/getDistance';
import AxiosInstance from '../../API/axiosInstance';
import { getAddresses } from '../../API/getAddresses';
import { IProfileData } from '../../views/Profile/types';

interface locationObj {
  lat: number;
  lng: number;
}

interface addressObj {
  id: string;
  name: string;
  lat: number;
  lng: number;
  map: Function;
  length: number;
}

interface Props {
  isCarService: boolean;
  cartState: any[];
  isAuth: boolean;
  setDistanceCharge: Function;
  getTotal: Function;
  profile: IProfileData | null
  isLogged?: boolean
}

export const Form: React.FC<Props> = ({
  isCarService,
  setDistanceCharge,
  cartState,
  getTotal,
  profile,
  isLogged
}) => {
  const formValue = useContext(FormContext);
  const [location, setLocation] = useState({
    lat: 13.9773532,
    lng: -89.5617106
  });
  const [destination, setDestination] = useState({
    lat: 13.9773532,
    lng: -89.5617106
  });

  const [selectedBrand, setBrand] = useState<string>('Honda');
  const [serviceDistance, setServiceDistance] = useState<number>(0);
  const [addressList, setAddressList] = useState<addressObj>();
  const [useExistingPick, setUseExistingPick] = useState(false);
  const [useExistingDrop, setUseExistingDrop] = useState(false);

  const { formState, setFormState } = formValue;
  let history = useHistory();

  const addressFilled = useExistingPick ? formState?.addressId : formState?.lat
  const destinationFilled = useExistingDrop ? formState?.dropAddressId : formState?.dropLat

  const getFormValidation = () => {
    if (isCarService) {
      return [
        formState?.carBrand,
        formState?.carModel,
        profile === null ? formState?.email : profile.email,
        profile === null ? formState?.name : profile.firstName,
        formState?.numberPlate,
        formState?.paymentType,
        formState?.visitDate,
        addressFilled,
        destinationFilled
      ]
    } else {
      return [
        profile === null ? formState?.email : profile.email,
        profile === null ? formState?.name : profile.firstName,
        formState?.visitDate,
        formState?.paymentType,
        addressFilled,
      ]
    }
  }


  const isFillForm = getFormValidation().filter(f => f === '').length === 0

  useEffect(() => {
    const select = document.querySelectorAll('select');
    const picker: NodeListOf<HTMLInputElement> = document.querySelectorAll('.datepicker');

    M.FormSelect.init(select);

    if (setFormState) {
      M.Datepicker.init(picker, {
        minDate: new Date(),
        onClose: function () {
          setFormState({ ...formState, visitDate: picker[0]["value"] })
        }
      });
    }

    if (formState && !isCarService) {
      const time: NodeListOf<HTMLInputElement> = document.querySelectorAll('.timepicker');

      M.Timepicker.init(time, {
        defaultTime: 'now',
      })
    }
  }, [formState, setFormState, isCarService])

  useEffect(() => {
    const KM = getDistance(location.lat, location.lng, destination.lat, destination.lng, 'K');
    // Cargo por distancia solo aplica para car service
    setDistanceCharge(KM > 8 ? (KM - 8).toFixed(2) : 0);
    setServiceDistance(KM);

    getAddresses(setAddressList)

    setFormState && setFormState({
      ...formState,
      direction: location,
      lat: location.lat,
      long: location.lng,
      wazeLink: `https://www.waze.com/es/livemap/directions?latlng=${location.lat}%2C${location.lng}&z=10`,
      gmapsLink: `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`
    })

    if (isCarService && setFormState) {
      setFormState({
        ...formState,
        dropAddress: destination,
        dropLat: destination.lat,
        dropLon: destination.lng,
        wazeLink: `https://www.waze.com/es/livemap/directions?z=10&to=ll.${destination.lat}%2C${destination.lng}&from=ll.${location.lat}%2C${location.lng}`,
        googleLink: `https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`,
        distance: serviceDistance
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination, history])


  const updateInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'carBrand') {
      formState && setBrand(value);
    }

    if (setFormState) {
      setFormState(() => {
        return { ...formState, [name]: value }
      })
    }
  }

  const position = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        setFormState && setFormState({
          ...formState,
          direction: location,
          lat: position.coords.latitude,
          long: position.coords.longitude
        })
      },
      err => console.error(err)
    );
  };

  const submitForm = () => {
    const data = {
      ...formState,
      lat: location.lat,
      lon: location.lng,
      serviceTypeIds: cartState.map(cart => cart.id),
      amount: getTotal()
    }

    AxiosInstance.post('/services', {
      ...data
    })
      .then(() => {
        localStorage.removeItem("cart")
        history.push('/confirmation')
      })
      .catch(error => {
        //if (error.response.status === 401 || error.response.status === 403) history.push('/login')
      });
  }

  const formStep = () => {
    if (isFillForm) {
      return (
        <>
          { formState?.Pago === "Tarjeta" && <p className="form-warning">Un agente de atención al cliente te contactará para ayudarte a procesar tu pago.</p>}
          <button
            onClickCapture={e => {
              e.preventDefault()
              submitForm()
            }}
            name="action"
            className="custom-button enabled-button modal-trigger"
          >
            Enviar Solicitud
          </button>
        </>
      )
    }
    return (
      <>
        <p className="form-warning">Completa todos los campos requeridos. Al completar el formulario un agente de servicio al cliente se pondrá en contacto contigo.</p>
        { formState?.Pago === "Tarjeta" && <p className="form-warning">Un agente de atención al cliente te contactará para ayudarte a procesar tu pago.</p>}
        <button className="custom-button disabled-button">
          Enviar Solicitud
				</button>
      </>
    )
  };

  const usePickUp = (obj: locationObj) => {
    if (obj.lat !== location.lat && obj.lng !== location.lng) {
      setLocation(obj)
      setFormState && setFormState({
        ...formState,
        addressId: null,
        lat: location.lat,
        long: location.lng
      })
    }
  }

  const useDestination = (obj: locationObj) => {
    if (obj.lat !== destination.lat && obj.lng !== destination.lng) {
      setDestination(obj)
      setFormState && setFormState({
        ...formState,
        dropAdddressId: null,
        dropLat: destination.lat,
        dropLon: destination.lng
      })
    }
  }

  return (
    <Styled className="container row horizontal-margin">
      <form
        id="checkoutForm"
        method="post"
        onSubmit={() => window.localStorage.clear()}
        className="pageclip-form col s12"
        action={process.env.REACT_APP_PAGECLIP_ACTION}
      >

        <label className={`main-label ${profile !== null && 'hidden'}`}>Información de contacto<span className="required-field">*</span></label>
        {profile === null ? <div className="row">
          <div className="input-field col s12">
            <input
              onChange={updateInput}
              required
              type="text"
              id="first_name"
              name="name"
              pattern="[A-Za-z ]*"
              className="validate"
            />
            <label htmlFor="first_name">Nombre completo<span className="required-field">*</span></label>
          </div>
        </div>
          : <input
            type="hidden"
            name="name"
            value={profile.firstName}
          />}

        {profile === null ? <div className='row'>
          <div className="input-field col s12 m6">
            <input
              onChange={updateInput}
              required
              id="email"
              type="email"
              name="email"
              className="validate"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            />
            <label htmlFor="email">Correo Electrónico<span className="required-field">*</span></label>
          </div>
        </div> :
          <div>
            <input
              type="hidden"
              name="phone"
              value={profile.phone ?? ''}
            />
            <input
              type="hidden"
              name="email"
              value={profile.email}
            />
          </div>
        }

        {isCarService && (
          <div className="row">
            <div className="input-field col s12 m6">
              <select defaultValue="" name="carBrand" onChange={updateInput} required>
                <option value="">Selecciona una marca</option>
                {Object.keys(CARS).sort().map((brand, index) => <option key={index} value={brand}>{brand}</option>)}
              </select>
              <label className="main-label">Marca de vehículo<span className="required-field">*</span></label>
            </div>

            <div className="input-field col s12 m6">
              <select defaultValue="" name="carModel" onChange={updateInput} required>
                <option value="">Selecciona un modelo</option>
                {formState && CARS[selectedBrand].sort().map((model: string, index: number) => <option key={index} value={model}>{model}</option>)}
              </select>
              <label className="main-label">Modelo de vehículo<span className="required-field">*</span></label>
            </div>
          </div>
        )}

        <div className="row">
          <div className="input-field col s12 m6">
            <input
              required
              type="text"
              id="datePicker"
              name="vistiDate"
              className="datepicker"
            />
            <label htmlFor="datePicker">Fecha de visita<span className="required-field">*</span></label>
          </div>
          <div className="input-field col s12 m6">
            <input
              onChange={updateInput}
              required
              id="phone_number"
              type="text"
              name="phone"
              className="validate"
              pattern="^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
            />
            <label htmlFor="phone_number">Número de teléfono<span className="required-field">*</span></label>
          </div>
        </div>

        <h3 className="main-label">Recorrido</h3>

        {isCarService &&
          <div id="car-service-maps">
            <p className="main-label">1. Tu ubicación<span className="required-field">*</span></p>
            {!useExistingPick ?
              <>
                <Map location={location} destination={usePickUp} />
                <>
                  <button onClickCapture={e => e.preventDefault()} className="custom-button add-service" onClick={position}>Ubicación actual</button>
                  {/* I'M SURE THIS CAN BE OPTIMIZED, BUT LET's KEEP IT LIKE THIS FOR THE FIRST RELEASE */}
                  {!isCarService && isLogged && (
                    <button
                      onClickCapture={e => {
                        e.preventDefault()
                        setUseExistingPick(true)
                      }}
                      className="custom-button add-service"
                    >
                      Usar una de mis direcciones
                    </button>
                  )}
                </>
              </>
              : (
                <>
                  <div className="input-field col s12 address-dropdown">
                    <select defaultValue="" name="addressId" onChange={updateInput} required>
                      <option value="">Selecciona una dirección</option>
                      {addressList && addressList.length > 0 && addressList.map((address: addressObj, index: number) => <option key={index} value={address.id}>{address.name}</option>)}
                    </select>
                    <label className="main-label">Dirección de partida<span className="required-field">*</span></label>
                  </div>
                  <>
                    <button
                      onClickCapture={e => {
                        e.preventDefault()
                        setUseExistingPick(false)
                      }}
                      className="custom-button add-service"
                    >
                      Usar mapa
                  </button>
                  </>
                </>
              )}

            <p className="main-label">2. Lugar de destino<span className="required-field">*</span></p>
            {!useExistingDrop ?
              <>
                <Map location={destination} destination={useDestination} />
                {/* I'M SURE THIS CAN BE OPTIMIZED, BUT LET's KEEP IT LIKE THIS FOR THE FIRST RELEASE */}
                {!isCarService && isLogged && (
                  <button
                    onClickCapture={e => {
                      e.preventDefault()
                      setUseExistingDrop(true)
                    }}
                    className="custom-button add-service"
                  >
                    Usar una de mis direcciones
                  </button>
                )}
              </> : (
                <>
                  <div className="input-field col s12 address-dropdown">
                    <select defaultValue="" name="dropAddressId" onChange={updateInput} required>
                      <option value="">Selecciona una dirección</option>
                      {addressList?.map((address: addressObj, index: number) => <option key={index} value={address.id}>{address.name}</option>)}
                    </select>
                    <label className="main-label">Dirección de destino<span className="required-field">*</span></label>
                  </div>
                  <button
                    onClickCapture={e => {
                      e.preventDefault()
                      setUseExistingDrop(false)
                    }}
                    className="custom-button add-service"
                  >
                    Usar mapa
                  </button>
                </>
              )}
            <div className="row">
              <div className="input-field col s12 m6">
                <input
                  onChange={updateInput}
                  required
                  id="carPlaque"
                  type="text"
                  name="numberPlate"
                  className="validate"
                />
                <label htmlFor="numberPlate">Placa<span className="required-field">*</span></label>
              </div>
            </div>
          </div>
        }

        {!isCarService && (
          <>
            {useExistingPick ? (
              <>
                <div className="input-field col s12 address-dropdown">
                  <select defaultValue="" name="addressId" onChange={updateInput} required>
                    <option value="">Selecciona una dirección</option>
                    {addressList && addressList.length > 0 && addressList.map((address: addressObj, index: number) => <option key={index} value={address.id}>{address.name}</option>)}
                  </select>
                  <label className="main-label">Dirección de partida<span className="required-field">*</span></label>
                </div>
                <button
                  onClickCapture={e => {
                    e.preventDefault()
                    setUseExistingPick(false)
                  }}
                  className="custom-button add-service"
                >
                  Usar mapa
                  </button>
              </>
            ) : (
                <>
                  <button onClickCapture={e => e.preventDefault()} className="custom-button enabled-button" onClick={position}>Ubicación actual</button>
                  <Map location={location} destination={usePickUp} />
                  <button
                    onClickCapture={e => {
                      e.preventDefault()
                      setUseExistingPick(true)
                    }}
                    className="custom-button add-service"
                  >
                    Usar una de mis direcciones
                </button>
                </>
              )}
          </>
        )}

        <div className="row">
          <div className="input-field col s12">
            <select defaultValue="" name="paymentType" onChange={updateInput} required>
              <option value="">Selecciona una opción</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="efectivo">Efectivo</option>
            </select>
            <label>Método de pago<span className="required-field">*</span></label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <input
              onChange={updateInput}
              type="text"
              id="message"
              name="Mensaje"
              autoComplete="off"
              className="validate"
            />
            <label htmlFor="message">Mensaje (opcional)</label>
          </div>
        </div>

        {formStep()}
      </form>
    </Styled>
  )
}

export default Form;